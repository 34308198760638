import React from "react";
import { FaBullhorn, FaBell, FaSignOutAlt, FaCog } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import http from "../../services/Interceptor";
import * as p from "../../constants/Permissions";
import * as url from "../../constants/Url";
import { UserInterface } from "../../interfaces/UserInterface";

interface Props {
	userState: UserInterface;
	userDispatch: React.Dispatch<any>;
	dispatch: React.Dispatch<any>;
}

export default function HeaderPanel({ userState, userDispatch, dispatch }: Props): JSX.Element {
	const history = useHistory();
	const isBlogger = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.HOME_BLOG_WRITER]);
	const isDiaperManager = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.DIAPER_APPLICATION]);

	const handleLogout = (): void => {
		sessionStorage.clear();
		localStorage.clear();
		userDispatch({
			type: "LOGOUT"
		});
		dispatch({
			type: "LOGOUT_SENIORS"
		});
		dispatch({
			type: "LOGOUT_ROOMS_UNITS"
		});
		http.post(`${url.BASEURL}/logout`);
		history.push("/");
	};

	return (
		<div className="header-panel row">
			<div className="col-6">
				<Link to={"/controlpanel/home"}>
					{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
						<img src="/img/white-logo-e4link.png" alt="logo eForLink" className="white-logo mt-2" />
					) : (
						<img src="/img/amd/amdlink-white.png" alt="logo AMD link" className="amd-white-logo" />
					)}
				</Link>
			</div>
			<div className="col-6">
				<div className="right">
					<Link to="/controlpanel/home">
						<FaBell className="control-panel-icon" role="button" />
					</Link>
					{isDiaperManager ? (
						<Link to={"/controlpanel/incosettings"}>
							<FaCog className="control-panel-icon" role="button" />
						</Link>
					) : null}
					{isBlogger ? (
						<Link to={"/controlpanel/blog"}>
							<FaBullhorn className="control-panel-icon" role="button" />
						</Link>
					) : null}
					<FaSignOutAlt className="control-panel-icon fake-link" role="button" onClick={handleLogout} />
				</div>
			</div>
		</div>
	);
}
