import React from "react";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import SelectHomeInput from "../layout/SelectHomeInput";
import AccessControl from "../layout/AccessControl";
import AmdProfileSync from "../components/DiaperConnected/AmdProfile/AmdProfileSync";
import * as p from "../constants/Permissions";
import Tabs from "../utils/Tabs/Tabs";
import { AmdDiaperSettingsTabs, DiaperSettingsTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const DiaperSettingsSync = ({ userState, homeId }: Props): JSX.Element => {
	const isAdmin = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]);
	const isAdminOrDiaperManager = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.DIAPER_APPLICATION]);
	const displayAmdSync = process.env.REACT_APP_AMD_SYNC_CONFIG === "true" ? true : false;

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					<>
						<Tabs tabsToDisplay={displayAmdSync ? AmdDiaperSettingsTabs : DiaperSettingsTabs} currentTab={"AMD_SYNC"} />
						<div className="big-card">
							{homeId ? (
								<AmdProfileSync homeId={homeId} isAdminOrDiaperManager={isAdminOrDiaperManager} isAdmin={isAdmin} userState={userState}/>
							) : (
								<SelectHomeInput />
							)}
						</div>
					</>
				}
			/>
		</AccessControl>
	);
};
export default DiaperSettingsSync;
