import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NewSeniorInterface, StoreInterface, UserInterface } from "../../../interfaces/UserInterface";
import { RoomInterface } from "../../../interfaces/HomeInterface";
import { steps } from "./AddSeniorSteps";
import * as p from "../../../constants/Permissions";
import HeaderContainer from "../../../layout/HeaderContainer";
import CreateFamilyUsersContainer from "../Family/CreateFamilyUsersContainer";
import StepContainer from "../../../utils/Stepper/StepContainer";
import AddSeniorContainer from "./AddSeniorContainer";
import AddSeniorResume from "./AddSeniorResume";

interface Props {
	homeId: number;
	store: StoreInterface;
	dispatch: React.Dispatch<any>;
	userState: UserInterface;
	newSeniorStore: NewSeniorInterface;
	newSeniorDispatch: React.Dispatch<any>;
	display:boolean;
}

const AddSenior = ({ homeId, store, dispatch, userState, newSeniorStore, newSeniorDispatch, display }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const roomsFromHome = store.home.rooms;

	useEffect(() => {
		const availableRooms = (roomsFromHome: RoomInterface[]): void => {
			const rooms = roomsFromHome.filter(room => room.senior_id === null);
			newSeniorDispatch({
				type: "SET_ROOMS_LIST",
				payload: [{ id: 0, name: t("senior.__addSeniorNoRoom__") }, ...rooms]
			});
		};
		availableRooms(roomsFromHome);
		// eslint-disable-next-line
	}, [roomsFromHome]);

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("senior.__addSenior__")}
				addButton={t("senior.__addSenior__")}
				link={"/senior/addsenior/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_CREATE_SENIOR]}
				userState={userState}
			/>
			{newSeniorStore.loading ? (
				<>
					<div className="spinner-border text-primary mr-3" role="status"></div>
					<span>
						<b>{t("senior.__addSeniorLoading__")}</b>
					</span>
				</>
			) : (
				<>
					<StepContainer steps={steps} stepToDisplay={newSeniorStore.step} />
					{newSeniorStore.step === "SENIOR" ? (
						<AddSeniorContainer newSeniorStore={newSeniorStore} newSeniorDispatch={newSeniorDispatch} />
					) : null}

					{newSeniorStore.step === "FAMILY" ? (
						<CreateFamilyUsersContainer
							newFamilyStore={newSeniorStore}
							newFamilyDispatch={newSeniorDispatch}
							newSeniorDispatch={newSeniorDispatch}
							isModal={false}
							onSubmit={(): void => {
								newSeniorDispatch({
									type: "GO_TO_RESUME"
								});
							}}
							display={display}
						/>
					) : null}

					{newSeniorStore.step === "RESUME" ? (
						<AddSeniorResume
							newSeniorStore={newSeniorStore}
							newSeniorDispatch={newSeniorDispatch}
							homeId={homeId}
							dispatch={dispatch}
						/>
					) : null}
				</>
			)}
		</div>
	);
};

export default AddSenior;
