import React, { useState, useEffect } from "react";
import { MailBoxTabs } from "../utils/Tabs/TabsToDisplay";
import { FamilyInterface, UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import NoData from "../layout/NoData";
import Loader from "../layout/Loader";
import NewMessage from "../components/Messages/NewMessage";
import Tabs from "../utils/Tabs/Tabs";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MessagesBox_NewMessage = ({ userState, homeId }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const canSendMessagesToFamily = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.CAN_INITIATE_MESSAGES_TO_FAMILY
	]);
	const [families, setFamilies] = useState([]);
	const [getFamiliesError, setGetFamiliesError] = useState(false);

	useEffect(() => {
		if (homeId && canSendMessagesToFamily) {
			setGetFamiliesError(false);
			http
				.get(`${url.HOMESURL}/${homeId}/family_users?seniors=active`)
				.then(res => {
					const allFamilies = res.data.family_users;
					const familiesWithSeniorsFromThisHome = allFamilies.map((family: FamilyInterface) => {
						return family.seniors.map(senior => {
							return (
								senior.home_id === homeId && {
									senior_id: senior.id,
									senior_first_name: senior.first_name,
									senior_last_name: senior.last_name,
									family_user_id: family.id,
									family_user_first_name: family.first_name,
									family_user_last_name: family.last_name,
									family_user_is_principal: family.principal
								}
							);
						});
					});
					const familiesWithSeniorsFromThisHomeArray = familiesWithSeniorsFromThisHome.flat();
					setFamilies(familiesWithSeniorsFromThisHomeArray.filter((elt: any) => elt));
				})
				.catch(() => setGetFamiliesError(true));
		}
		// eslint-disable-next-line
	}, [homeId, seniorsFromHome, canSendMessagesToFamily]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.CAN_INITIATE_MESSAGES_TO_FAMILY, p.SUPER_ADMIN, p.ADMIN]}>
			<Tabs tabsToDisplay={MailBoxTabs} currentTab={"INITIATE_MESSAGE"} />
			<div className="big-card">
				{homeId ? (
					canSendMessagesToFamily ? (
						families.length > 0 ? (
							<NewMessage seniorsFromHome={seniorsFromHome} families={families} homeId={homeId} />
						) : getFamiliesError ? (
							<NoData />
						) : (
							<Loader />
						)
					) : (
						<NoData />
					)
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default MessagesBox_NewMessage;
