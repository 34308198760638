import React, { useReducer, useEffect } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { SeniorTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import AddSenior from "../components/Seniors/CreateSenior/AddSenior";
import { NewSeniorReducer, newSeniorState } from "../context/reducers/NewSeniorReducer";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	display:boolean;
}

const Seniors_AddSenior = ({ userState, homeId}: Props): JSX.Element => {
	const { store, dispatch } = useStoreData({ userState, homeId });
	const [newSeniorStore, newSeniorDispatch] = useReducer(NewSeniorReducer, newSeniorState);

	useEffect(() => {
		return function cleanup() {
			newSeniorDispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	return (
		<>
			<Tabs tabsToDisplay={SeniorTabs} currentTab={"SENIORS"} />
			<AccessControl
				userPermissions={userState.userConnected.permissions}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_CREATE_SENIOR]}>
				{homeId ? (
					<AddSenior
						homeId={homeId}
						store={store}
						dispatch={dispatch}
						userState={userState}
						newSeniorStore={newSeniorStore}
						newSeniorDispatch={newSeniorDispatch}
						display={true}
					/>
				) : (
					<SelectHomeInput />
				)}
			</AccessControl>
		</>
	);
};
export default Seniors_AddSenior;
