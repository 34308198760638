import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import MyInputText from "../../../utils/Form/MyInputText";
import MyInputEmail from "../../../utils/Form/MyInputEmail";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

const UpdateFamilyUser = ({ show, setShow }) => {
	const { t } = useTranslation("common");
	const family = show.info;
	const [error, setError] = useState(false);
	const methods = useForm();
	const { handleSubmit } = methods;

	const onSubmit = values => {
		setError(false);
		const data = {
			family_user: {
				email: values.email,
				first_name: values.firstname,
				last_name: values.lastname,
				phone: values.phone,
				login: values.login
			}
		};
		http
			.put(`${url.FAMILYURL}/${family.id}`, data)
			.then(() => setShow({ show: false, info: null, title: "Compte Family", success: true }))
			.catch(() => setError(true));
	};

	return (
		<FormProvider {...methods}>
			{error && (
				<p className="error-msg">
					<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
						<FaTimes />
					</span>
					{t("common.__errorOccured__")}
				</p>
			)}

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row mt-3">
					<div className="col-md-6">
						<MyInputText
							label={t("common.__firstname__")}
							name="firstname"
							behavior={{ required: true }}
							value={family.first_name}
						/>
					</div>
					<div className="col-md-6">
						<MyInputText
							label={t("common.__name__")}
							name="lastname"
							behavior={{ required: true }}
							value={family.last_name}
						/>
					</div>
					<div className="col-md-6">
						<MyInputText label={t("common.__login__")} name="login" behavior={{ required: true }} value={family.login} />
					</div>
					<div className="col-md-6">
						<MyInputEmail
							label={t("common.__mail__")}
							name="email"
							behavior={{ required: true, pattern: /^\S+@\S+\.\S+$/ }}
							value={family.email}
						/>
					</div>
					<div className="col-md-6">
						<MyInputText
							label={t("common.__phone__")}
							name="phone"
							behavior={{ required: false, maxLength: 15, minLength: 7, pattern: /^[0-9+-]*$/ }}
							value={family.phone}
						/>
					</div>
				</div>
				<button type="submit" className="btn btn-primary btn-sm mt-2">
					{t("common.__save__")}
				</button>
			</form>
		</FormProvider>
	);
};

export default UpdateFamilyUser;
