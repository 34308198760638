import React from "react";
import { Link } from "react-router-dom";
import "./stepper.scss";

interface Props {
	steps: { id: number; step: string; name: string; link?: string }[];
	stepToDisplay: string;
}

const StepContainer = ({ steps, stepToDisplay }: Props): JSX.Element => {
	const currentStep = steps.find(step => step.step === stepToDisplay);

	return (
		<div className="stepper mt-4">
			<div className="stepper-container">
				<ul className="progressbar">
					{steps.map(step => (
						<li
							key={step.id}
							className={
								step.step === stepToDisplay ? "active-step" : currentStep && currentStep.id >= step.id ? "checked-step" : ""
							}>
							{currentStep && currentStep.id > step.id && step.link ? (
								<Link to={step.link}>
									<span className="meta">{step.name}</span>
								</Link>
							) : (
								<span className="meta">{step.name}</span>
							)}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default StepContainer;
