import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowAltCircleRight, FaTimesCircle } from "react-icons/fa";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import MyInputEmail from "../../../utils/Form/MyInputEmail";
import MySecretPassword from "../../../utils/Form/MySecretPassword";
import { NewSeniorInterface } from "../../../interfaces/UserInterface";

interface Props {
	newFamilyStore: NewSeniorInterface;
	newFamilyDispatch: React.Dispatch<any>;
	newSeniorDispatch: React.Dispatch<any>;
	display: boolean;
}

interface FormValues {
	firstname: string;
	lastname: string;
	phone: string;
	email: string;
	login: string;
	password: string | null;
}

const CreateFamilyUser = ({
	newFamilyStore,
	newFamilyDispatch,
	newSeniorDispatch,
	display
}: Props): JSX.Element | null => {
	const { t } = useTranslation("common");
	const [personalPassword, setPersonalPassword] = useState(false);
	const methods = useForm<FormValues>();
	const { handleSubmit, reset } = methods;

	const onSubmit = (values: FormValues): void => {
		const family = {
			first_name: values.firstname,
			last_name: values.lastname,
			phone: values.phone,
			email: values.email,
			login: values.email,
			password: personalPassword ? values.password : null
		};
		newFamilyDispatch({
			type: "SET_NEW_FAMILY",
			payload: family
		});
		setPersonalPassword(false);
		reset();
	};

	return newFamilyStore.displayAddFamilyUser ? (
		<div className="header-details mb-2">
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)} data-testid="createFamilyUserForm">
					<div className="row">
						<div className="col-md-6">
							<MyInputText label={t("common.__firstname__")} name="firstname" behavior={{ required: true }} />
						</div>
						<div className="col-md-6">
							<MyInputText label={t("common.__name__")} name="lastname" behavior={{ required: true }} />
						</div>
						<div className="col-md-6">
							<MyInputEmail
								label={t("common.__mail__")}
								name="email"
								behavior={{ required: true, pattern: /^\S+@\S+\.\S+$/ }}
							/>
						</div>
						<div className="col-md-6">
							<MyInputText
								label={t("common.__phone__")}
								name="phone"
								behavior={{ required: false, maxLength: 15, minLength: 7, pattern: /^[0-9+-]*$/ }}
							/>
						</div>

						<div className="col-12">
							<div className="alert alert-warning mt-2">
								{personalPassword ? (
									<>
										<div className="right table-icon" onClick={(): void => setPersonalPassword(false)}>
											<FaTimesCircle />
										</div>
										<p className="mb-3">
											<b>{t("common.__password__")}</b>
										</p>
										<MySecretPassword
											label={t("users.__passwordWarning__")}
											name="password"
											behavior={{
												required: true,
												pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.\-_])[A-Za-z\d@$!%*?&.\-_]{8,}$/
											}}
										/>
									</>
								) : (
									<>
										{t("family.__passwordWarning__")}
										<br />
										<b className="blue fake-link" onClick={(): void => setPersonalPassword(true)}>
											{t("common.__addPassword__")}
										</b>
									</>
								)}
							</div>
						</div>
						<div className="col-12">
							<p className="meta mb-3 mt-3">
								<FaArrowAltCircleRight /> {t("family.__warningFamilyLogin__")}
							</p>
						</div>
						<div className="col-12">
							<div className="d-flex">
								{display && (
									<button
										type="button"
										className="btn btn-sm btn-outline-secondary mr-2"
										onClick={(): void => {
											newSeniorDispatch({
												type: "GO_TO_SENIOR"
											});
										}}>
										{t("common.__cancel__")}
									</button>
								)}
								{display && (
									<button
										type="button"
										className="btn btn-secondary text-white"
										onClick={(): void => {
											newSeniorDispatch({
												type: "GO_TO_RESUME"
											});
										}}>
										{t("common.__skip__")}
									</button>
								)}
								<button type="submit" className="btn btn-info btn-sm  ml-auto">
									{t("common.__add__")}
								</button>
							</div>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	) : null;
};

export default CreateFamilyUser;
