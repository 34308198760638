import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import AlertCardsContainer from "./AlertCardsContainer";
import Toggle from "../../../utils/Toggle";
import ModalContainer, { ShowState } from "../../../utils/ModalContainer";
import RemoveSensorToSenior from "../Sensors/RemoveSensorToSenior";
import AddOrControlProtectionToSenior from "../AddOrControlProtectionToSenior";
import AffectSensorToSenior from "../Sensors/AffectSensorToSenior";
import { HomeUnitInterface, UserInterface } from "../../../interfaces/UserInterface";
import { DiaperSeniorInterface, DiaperStoreInterface } from "../../../interfaces/DiaperInterface";

interface Props {
	showDiaperChange: ShowState;
	setShowDiaperChange: React.Dispatch<React.SetStateAction<ShowState>>;
	showRemoveSensor: ShowState;
	setShowRemoveSensor: React.Dispatch<React.SetStateAction<ShowState>>;
	showAffectSensor: ShowState;
	setShowAffectSensor: React.Dispatch<React.SetStateAction<ShowState>>;
	userState: UserInterface;
	homeUnits: HomeUnitInterface[];
	floors: any[];
	diaperStore: DiaperStoreInterface;
	alerts: DiaperSeniorInterface[];
	setUpdate: (arg: boolean) => void;
	isControlPanel: boolean;
}

export default function AlertCards({
	showDiaperChange,
	setShowDiaperChange,
	showRemoveSensor,
	setShowRemoveSensor,
	userState,
	homeUnits,
	floors,
	diaperStore,
	alerts,
	showAffectSensor,
	setShowAffectSensor,
	setUpdate,
	isControlPanel
}: Props): JSX.Element {
	const { t } = useTranslation("common");
	const searchInput = useRef<any>(null);
	const diapers = diaperStore.diapers;
	const seniors = diaperStore.seniors;
	const seniorsWithoutAlert = useMemo(() => seniors.filter(data => !data.alert), [seniors]);
	const seniorsList = useMemo(() => [...alerts, ...seniorsWithoutAlert], [alerts, seniorsWithoutAlert]);
	const [homeUnitSelected, setHomeUnitSelected] = useState<null | number>(null);
	const [floorSelected, setFloorSelected] = useState(null);
	const [sortSelected, setSortSelected] = useState("by_name");
	const [withSensor, setWithSensor] = useState(false);
	const [seniorsToDisplay, setSeniorsToDisplay] = useState(seniorsList);

	useEffect(() => {
		const handleCardsToDisplay = (withSensor: boolean, homeUnitSelected: any, floorSelected: any): void => {
			searchInput.current.value = "";
			if (withSensor) {
				const seniorsWithSensors = seniorsList.filter(senior => senior.sensor_id);
				if (homeUnitSelected && floorSelected) {
					const floor = floorSelected === t("common.__rdc__") ? 0 : floorSelected;
					setSeniorsToDisplay(
						seniorsWithSensors.filter(senior => senior.home_unit_id === homeUnitSelected && senior.room_floor === floor)
					);
				} else if (homeUnitSelected) {
					setSeniorsToDisplay(seniorsWithSensors.filter(senior => senior.home_unit_id === homeUnitSelected));
				} else if (floorSelected) {
					const floor = floorSelected === t("common.__rdc__") ? 0 : floorSelected;
					setSeniorsToDisplay(seniorsWithSensors.filter(senior => senior.room_floor === floor));
				} else {
					setSeniorsToDisplay(seniorsWithSensors);
				}
			} else {
				if (homeUnitSelected && floorSelected) {
					const floor = floorSelected === t("common.__rdc__") ? 0 : floorSelected;
					setSeniorsToDisplay(
						seniorsList.filter(senior => senior.home_unit_id === homeUnitSelected && senior.room_floor === floor)
					);
				} else if (homeUnitSelected) {
					setSeniorsToDisplay(seniorsList.filter(senior => senior.home_unit_id === homeUnitSelected));
				} else if (floorSelected) {
					const floor = floorSelected === t("common.__rdc__") ? 0 : floorSelected;
					setSeniorsToDisplay(seniorsList.filter(senior => senior.room_floor === floor));
				} else {
					setSeniorsToDisplay(seniorsList);
				}
			}
		};
		handleCardsToDisplay(withSensor, homeUnitSelected, floorSelected);
	}, [seniorsList, homeUnitSelected, floorSelected, withSensor]);

	const sensorsAlreadyUsed = seniors.filter(senior => senior.sensor_id);
	const sensorsIdsAlreadyUsed = sensorsAlreadyUsed.map(senior => senior.sensor_id);
	const sensorsAvailable = diaperStore.sensors.filter(sensor => !sensorsIdsAlreadyUsed.includes(sensor.id));

	const handleSearch = (e: any): void => {
		setHomeUnitSelected(null);
		setFloorSelected(null);
		const seniors = seniorsList.filter(senior => senior.name.toLowerCase().includes(e.toLowerCase()));
		setSeniorsToDisplay(seniors);
	};

	const toggleFilter = (value: any): void => {
		setWithSensor(!value);
	};

	return (
		<>
			<div className="d-flex justify-content-between">
				<p className="meta mt-3">
					{t("common.__filterBy__")} {t("common.__senior__")} :
				</p>
				<Toggle
					isToggle={withSensor}
					value={withSensor}
					action={toggleFilter}
					textActif={` ${t("diaper.__withSensor__")} (${seniorsToDisplay.length})`}
					textInactif={` ${t("common.__all__")} (${seniorsToDisplay.length})`}
					meta
				/>
			</div>
			<div className="dont-print-this">
				<input
					type="text"
					placeholder={t("mailbox.__searchBySenior__")}
					onChange={(e: any): void => handleSearch(e.target.value)}
					className="form-control form-control-sm mt-2 mb-2"
					ref={searchInput}
				/>

				{homeUnits.length > 1 ? (
					<div className="mt-3">
						<p className="meta">
							{t("common.__filterBy__")} {t("common.__homeUnit__")} :
						</p>
						<span
							className={homeUnitSelected ? "category-outline-badge grey" : "category-badge blue"}
							role="button"
							onClick={(): void => setHomeUnitSelected(null)}>
							{t("common.__all__")}
						</span>
						{homeUnits.map(homeUnit => (
							<span
								key={homeUnit.id}
								role="button"
								className={homeUnitSelected === homeUnit.id ? "category-badge blue" : "category-outline-badge grey"}
								onClick={(): void => setHomeUnitSelected(homeUnit.id)}>
								{homeUnit.name}
							</span>
						))}
					</div>
				) : null}
				{floors.length > 1 ? (
					<div className="mt-3">
						<p className="meta">
							{t("common.__filterBy__")} {t("home.__floor__")} :
						</p>
						<span
							className={floorSelected ? "category-outline-badge grey" : "category-badge blue"}
							role="button"
							onClick={(): void => setFloorSelected(null)}>
							{t("common.__all__")}
						</span>
						{floors.map((floor: any, index: any) => (
							<span
								key={index}
								role="button"
								className={floorSelected === floor.name ? "category-badge blue" : "category-outline-badge grey"}
								onClick={(): void => setFloorSelected(floor.name)}>
								{floor.name === t("common.__rdc__") ? null : t("home.__floor__")} {floor.name}
							</span>
						))}
					</div>
				) : null}
				
				<div className="mt-3">
					<p className="meta">
						{t("common.__sortBy__")} :
					</p>						
					<span
						key={"sortName"}
						role="button"
						className={sortSelected === "by_name" ? "category-badge blue" : "category-outline-badge grey"}
						onClick={(): void => setSortSelected("by_name")}>
						{t("common.__name__")}
					</span>
					<span
						key={"sortNameAndPriority"}
						role="button"
						className={sortSelected === "by_name_and_priority" ? "category-badge blue" : "category-outline-badge grey"}
						onClick={(): void => setSortSelected("by_name_and_priority")}>
						{t("common.__priority__")}
					</span>
					<span
						key={"sortSensor"}
						role="button"
						className={sortSelected === "sensor" ? "category-badge blue" : "category-outline-badge grey"}
						onClick={(): void => setSortSelected("sensor")}>
						{t("common.__sensor__")}
					</span>
				</div>
				
			</div>

			<div className="d-flex flex-row flex-wrap mt-3">
				<AlertCardsContainer
					setShowDiaperChange={setShowDiaperChange}
					setShowRemoveSensor={setShowRemoveSensor}
					setShowAffectSensor={setShowAffectSensor}
					sortSelected={sortSelected}
					userState={userState}
					seniorsToDisplay={seniorsToDisplay}
					isControlPanel={isControlPanel}
				/>
			</div>
			<ModalContainer show={showDiaperChange} setShow={setShowDiaperChange}>
				<AddOrControlProtectionToSenior
					diapers={diapers}
					userLocale={userState.userConnected.locale}
					show={showDiaperChange}
					setShow={setShowDiaperChange}
					setUpdate={setUpdate}
				/>
			</ModalContainer>
			<ModalContainer show={showRemoveSensor} setShow={setShowRemoveSensor}>
				<RemoveSensorToSenior show={showRemoveSensor} setShow={setShowRemoveSensor} />
			</ModalContainer>
			<ModalContainer show={showAffectSensor} setShow={setShowAffectSensor}>
				<AffectSensorToSenior
					show={showAffectSensor}
					setShow={setShowAffectSensor}
					sensorsAvailable={sensorsAvailable}
					setUpdate={setUpdate}
				/>
			</ModalContainer>
		</>
	);
}
