import React from "react";
import { useTranslation } from "react-i18next";
import PieChartContainer from "../../../utils/PieChartContainer";

export default function DisplayPieChart({
	display,
	data,
	height = 320,
	innerRadius = 45,
	outerRadius = 85,
	paddingAngle = 1,
	title
}) {
	const { t } = useTranslation("common");

	const hasValues = values => {
		return values.every(elt => elt.value > 0);
	};

	return display ? (
		<div className="col">
			<h5 className="center">{title}</h5>
			{hasValues(data) ? (
				<PieChartContainer
					data={data}
					dataKey="value"
					height={height}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					paddingAngle={paddingAngle}
				/>
			) : (
				<p className="meta center">{t("common.__noElement__")}</p>
			)}
		</div>
	) : null;
}
