import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";
import { ShowState } from "../../../utils/ModalContainer";

interface Props {
	show: ShowState;
	setShow: React.Dispatch<React.SetStateAction<ShowState>>;
	diaperDispatch?: React.Dispatch<any>;
}

export default function RemoveSensorToSenior({ show, setShow, diaperDispatch }: Props): JSX.Element {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const senior = show.info.senior;

	const onSubmit = (e: any): void => {
		e.preventDefault();
		setError(false);
		const data = {
			diaper_sensor_id: senior.sensor_id,
			senior_id: senior.id
		};
		http
			.post(`${url.BASEURL}/rht/unaffect/diaper_sensor/from/senior`, data)
			.then(() => {
				if (diaperDispatch) {
					diaperDispatch({
						type: "UNAFFECT_SENSOR_TO_SENIOR",
						payload: senior
					});
				}
				setShow({ show: false, info: null, title: "", success: true });
			})
			.catch(() => setError(true));
	};

	return (
		<form onSubmit={onSubmit} className="mb-2 mt-3">
			<HandleError error={error} setError={setError} message={t("common.__editError__")} />
			<p className="mb-3">
				{t("diaper.__removeSensorToSenior__")} : <b>{senior.name}.</b>
			</p>
			<button
				type="button"
				className="btn btn-sm btn-outline-secondary mr-2"
				onClick={(): void => setShow({ show: false, info: { senior: null }, success: false })}>
				{t("common.__cancel__")}
			</button>
			<button type="submit" className="btn btn-sm btn-danger">
				{t("common.__validate__")}
			</button>
		</form>
	);
}
