import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import OnlyTimePicker from "../../../utils/DatePicker/OnlyTimePicker";
import OnlyDatePicker from "../../../utils/DatePicker/OnlyDatePicker";
import SaturationStatistics from "./SaturationStatistics";
import SeniorWithDiapersDetails from "./SeniorWithDiaperDetails";
import SeniorsSaturationsList from "./SeniorsSaturationsList";
import SeniorWithDiaperSaturationChart from "./SeniorWithDiaperSaturationChart";
import SaturationBoards from "./SaturationBoards";
import PrintIcon from "../../Buttons/PrintIcon";
import { MdFilterAlt } from "react-icons/md";
import { addYears } from "date-fns";
import { MdOutlineFileDownload } from "react-icons/md";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { format } from "date-fns";
import * as perms from "../../../constants/Permissions";
import HandleError from "../../../layout/HandleError";

export default function SeniorsSaturationStatistics({
	userState,
	startDate,
	endDate,
	saturationValues,
	setStartDate,
	setEndDate,
	startTime,
	setStartTime,
	endTime,
	setEndTime,
	statistics,
	homeId,
	delay,
	setDelay,
	setStatistics,
	setSaturationValues,
	seniorsWithSensor,
	selectedSenior,
	setSelectedSenior
}) {
	const { t } = useTranslation("common");
	const repartition = statistics ? statistics.range_percentages : [];
	const userLocale = userState.userConnected.locale;
	const hasAdminAccess = userState.userConnected.is_in([perms.ADMIN, perms.SUPER_ADMIN]);
	const [error, setError] = useState("");

	const handleStartDateChange = date => {
		setStartDate(date);
		localStorage.setItem("startDate", date.toISOString());

		const storedEndDate = localStorage.getItem("endDate");
		if (storedEndDate) {
			const existingEndDate = new Date(storedEndDate);

			if (existingEndDate <= date) {
				const newEndDate = new Date(date);
				newEndDate.setDate(newEndDate.getDate() + 1);
				setEndDate(newEndDate);
				localStorage.setItem("endDate", newEndDate.toISOString());
			} else {
				const oneYearAfterStartDate = addYears(date, 1);
				const today = new Date();
				if (existingEndDate > oneYearAfterStartDate) {
					let adjustedEndDate = oneYearAfterStartDate;
					if (adjustedEndDate > today) {
						adjustedEndDate = today;
					}
					setEndDate(adjustedEndDate);
					localStorage.setItem("endDate", adjustedEndDate.toISOString());
				}
			}
		} else {
			const newEndDate = addYears(date, 1);
			const today = new Date();
			if (newEndDate > today) {
				setEndDate(today);
				localStorage.setItem("endDate", today.toISOString());
			} else {
				setEndDate(newEndDate);
				localStorage.setItem("endDate", newEndDate.toISOString());
			}
		}
	};

	const handleEndDateChange = date => {
		setEndDate(date);
		localStorage.setItem("endDate", date.toISOString());

		const storedStartDate = localStorage.getItem("startDate");
		if (storedStartDate) {
			const existingStartDate = new Date(storedStartDate);
			if (date < existingStartDate) {
				const adjustedStartDate = new Date(date);
				adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);
				setStartDate(adjustedStartDate);
				localStorage.setItem("startDate", adjustedStartDate.toISOString());
			} else if (date > existingStartDate) {
				const oneYearAfterStartDate = addYears(existingStartDate, 1);
				if (date < oneYearAfterStartDate) {
					// if true , leave it un changed
				} else {
					const newStartDate = addYears(date, -1);
					setStartDate(newStartDate);
					localStorage.setItem("startDate", newStartDate.toISOString());
				}
			}
		} else {
			const newStartDate = addYears(date, -1);
			setStartDate(newStartDate);
			localStorage.setItem("startDate", newStartDate.toISOString());
		}
	};

	const handleStartTimeChange = date => {
		setStartTime(date);
		localStorage.setItem("startTime", date.toISOString());
	};

	const handleEndTimeChange = date => {
		setEndTime(date);
		localStorage.setItem("endTime", date.toISOString());
	};
	function clearSaturationFilters() {
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);

		const keysToRemove = ["startDate", "endDate", "startTime", "endTime", "selectedSenior"];

		keysToRemove.forEach(key => localStorage.removeItem(key));

		setStartDate(yesterday);
		setEndDate(today);
		setSelectedSenior(null);

		const currentHourWithMinutes = new Date();
		currentHourWithMinutes.setSeconds(0, 0);

		setStartTime(currentHourWithMinutes);
		setEndTime(new Date(currentHourWithMinutes));
	}

	const DownloadSensorData = () => {
		if (homeId && hasAdminAccess) {
			const startDateFormatted = format(startDate, "yyyy-MM-dd");
			const endDateFormatted = format(endDate, "yyyy-MM-dd");
			const startTimeFormatted = format(startTime, "HH:mm:ss");
			const endTimeFormatted = format(endTime, "HH:mm:ss");

			const data = {
				from: `${startDateFormatted} ${startTimeFormatted}`,
				to: `${endDateFormatted} ${endTimeFormatted}`
			};

			if (selectedSenior && selectedSenior.value) {
				data.senior_id = selectedSenior.value;
			} else {
				data.home_id = homeId;
			}

			http
				.post(`${url.BASEURL}/rht/diaper_sensors/export`, data, { responseType: "blob" })
				.then(res => {
					const urlBlob = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = urlBlob;
					link.setAttribute(
						"download",
						`${selectedSenior ? t("diaper.__sensorsDataBySenior__") : t("diaper.__sensorsDataByHome__")}.xlsx`
					);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(error => {
					if (error.response && error.response.status === 400) {
						console.error("Error 400: Bad Request", error);
						setError(t("diaper.__selectAnotherPeriodOrSenior__"));
					}
				});
		}
	};

	return (
		<>
			<div className="big-card dont-print-this">
				<div className="d-flex justify-content-between align-items-center">
					<h2>{t("diaper.__provisionMonitoring__")}</h2>
					<div>
						<button className="btn btn-sm btn-primary mt-2 dont-print-this" onClick={() => window.print()}>
							{t("common.__print__")} <PrintIcon />
						</button>
						<button
							type="button"
							className="btn btn-sm btn-primary mt-2 ml-2 dont-print-this"
							onClick={clearSaturationFilters}>
							{t("common.__resetFilters__")} <MdFilterAlt size={22} />
						</button>
						{hasAdminAccess ? (
							<button type="button" className="btn btn-sm btn-dark mt-2 ml-2 dont-print-this" onClick={DownloadSensorData}>
								{selectedSenior ? t("diaper.__downloadSensorDataBySenior__") : t("diaper.__downloadSensorDataByHome__")}{" "}
								<MdOutlineFileDownload size={22} />
							</button>
						) : null}
					</div>
				</div>
				<h2>{t("diaper.__saturationMonitoring__")}</h2>
				<div className="row mt-3">
					<div className="col-sm-2 mt-2">
						<p>
							<b>{t("common.__dates__")} : </b>
						</p>
					</div>
					<div className="col-sm-5 mt-2">
						<OnlyDatePicker startDate={startDate} setStartDate={handleStartDateChange} userLocale={userLocale} type="START" />
					</div>
					<div className="col-sm-5 mt-2">
						<OnlyTimePicker startTime={startTime} setStartTime={handleStartTimeChange} userLocale={userLocale} />
					</div>
					<div className="col-sm-2">
						<p>{t("common.__toward__")}</p>
					</div>
					<div className="col-sm-5">
						<OnlyDatePicker startDate={endDate} setStartDate={handleEndDateChange} userLocale={userLocale} type="END" />
					</div>
					<div className="col-sm-5">
						<OnlyTimePicker startTime={endTime} setStartTime={handleEndTimeChange} userLocale={userLocale} />
					</div>

					<div className="col-12">
						<p className="meta">{t("common.__filterBySenior__")} :</p>
						<Select
							placeholder={t("senior.__selectSenior__")}
							name="seniors"
							options={seniorsWithSensor}
							className="basic-multi-select mb-2 mt-3"
							classNamePrefix="select"
							onChange={e => {
								localStorage.setItem("selectedSenior", JSON.stringify(e));
								setSelectedSenior(e);
							}}
							isClearable
							value={selectedSenior ? selectedSenior : 0}
						/>
						<HandleError error={error} setError={setError} message={error} />
					</div>
				</div>
			</div>

			<SaturationBoards statistics={statistics} />

			{selectedSenior ? (
				<SeniorWithDiaperSaturationChart
					seniorId={selectedSenior.value}
					startDate={startDate}
					endDate={endDate}
					startTime={startTime}
					endTime={endTime}
					userLocale={userState.userConnected.locale}
				/>
			) : null}

			<SaturationStatistics
				startDate={startDate}
				endDate={endDate}
				startTime={startTime}
				endTime={endTime}
				homeId={homeId}
				delay={delay}
				setDelay={setDelay}
				repartition={repartition}
				setStatistics={setStatistics}
				setSaturationValues={setSaturationValues}
			/>

			{saturationValues.length > 0 ? (
				<div className="big-card">
					<h5>{t("diaper.__antechronologicalList__")}</h5>
					{selectedSenior ? (
						<SeniorWithDiapersDetails saturationValues={saturationValues} userState={userState} />
					) : (
						<SeniorsSaturationsList saturationValues={saturationValues} userState={userState} />
					)}
				</div>
			) : null}
		</>
	);
}
