import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import LanguageSwitcher from "../../i18n/LanguageSwitcher";
import { UserInterface } from "../../interfaces/UserInterface";

interface Props {
	userState: UserInterface;
	userDispatch: React.Dispatch<any>;
}

const UpdateLanguage = ({ userState, userDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [userLocale, setUserLocale] = useState(userState.userConnected.locale);
	const [UpdateLanguage, setUpdateLanguage] = useState(false);

	const displayLanguageName = (locale: string): string => {
		if (locale === "fr") {
			return t("users.__french__");
		} else if (locale === "de") {
			return t("users.__german__");
		} else if (locale === "nl") {
			return t("users.__dutch__");
		} else {
			return t("users.__english__");
		}
	};

	const onSubmit = (): void => {
		const userData = {
			user: {
				locale: userLocale,
				uiprefs: {
					inco_ringtone: userState.userConnected.uiprefs ? userState.userConnected.uiprefs.inco_ringtone : 1
				}
			}
		};
		http.put(`${url.BASEURL}/me`, userData).then(res => {
			userDispatch({
				type: "UPDATE_MY_ACCOUNT",
				payload: res.data.user
			});
			localStorage.setItem("locale", JSON.stringify(res.data.user.locale));
			if (res.data.user.locale === "fr") {
				localStorage.setItem("i18next", "fr-FR");
			} else if (res.data.user.locale === "nl") {
				localStorage.setItem("i18next", "nl-BE");
			} else {
				localStorage.setItem("i18next", "en-EN");
			}
			setUpdateLanguage(false);
		});
	};

	return UpdateLanguage ? (
		<div className="d-flex">
			<LanguageSwitcher userLocale={userLocale} setUserLocale={setUserLocale} />
			<button type="button" onClick={onSubmit} className="btn btn-sm btn-outline-primary ml-2">
				{t("common.__save__")}
			</button>
		</div>
	) : (
		<div role="button" onClick={(): void => setUpdateLanguage(true)}>
			<input
				type="text"
				value={displayLanguageName(userLocale)}
				disabled
				className="form-control form-control-sm fake-link"
			/>
		</div>
	);
};

export default UpdateLanguage;
