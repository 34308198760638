import React, { useEffect, useState } from "react";
import useDiaperStore from "../../hooks/useDiaperStore";
import ControlPanelComponent from "./ControlPanelComponent";
import { HomeUnitInterface, SeniorInterface, UserInterface } from "../../interfaces/UserInterface";
import { BlogPostInterface } from "../../interfaces/HomeInterface";
import { DiaperWebSocket, PanelWebSocket } from "../../utils/WebSocket";

interface Props {
	homeId: number;
	userState: UserInterface;
	socket: any;
	isDiaperManager: boolean;
	isMessageManager: boolean;
	display: string;
	seniorsFromHome: SeniorInterface[];
	homeUnits: HomeUnitInterface[];
	post: BlogPostInterface | null;
}

export default function ControlPanelSocket({
	homeId,
	userState,
	socket,
	isDiaperManager,
	isMessageManager,
	display,
	seniorsFromHome,
	homeUnits,
	post
}: Props): JSX.Element {
	const [fetchMessages, setFetchMessages] = useState(false);
	const [fetchCalendar, setFetchCalendar] = useState(false);
	const [refetch, setRefetch] = useState(false);
	const { diaperStore, diaperDispatch } = useDiaperStore({ userState, homeId, refetch });

	const selectedRingtone =
		userState.userConnected.uiprefs && userState.userConnected.uiprefs.inco_ringtone
			? userState.userConnected.uiprefs.inco_ringtone
			: 1;

	useEffect(() => {
		if (socket && homeId) {
			PanelWebSocket(socket, homeId, setFetchCalendar, setFetchMessages);
		}
	}, [socket, homeId]);

	useEffect(() => {
		if (socket && homeId && isDiaperManager) {
			DiaperWebSocket(socket, homeId, setRefetch, selectedRingtone);
		}
	}, [socket, homeId, isDiaperManager]);

	return (
		<ControlPanelComponent
			homeId={homeId}
			fetchMessages={fetchMessages}
			fetchCalendar={fetchCalendar}
			refetch={refetch}
			userState={userState}
			isDiaperManager={isDiaperManager}
			display={display}
			diaperStore={diaperStore}
			diaperDispatch={diaperDispatch}
			seniorsFromHome={seniorsFromHome}
			homeUnits={homeUnits}
			isMessageManager={isMessageManager}
			post={post}
		/>
	);
}
