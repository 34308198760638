import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";

const MyInputPassword = props => {
	const { t } = useTranslation("common");
	const [selectedValue, setSelectedValue] = useState(props.value);
	const {
		register,
		formState: { errors }
	} = useFormContext();
	const [viewPassword, setViewPassword] = useState(false);

	const onChange = e => {
		if (props.onChange !== undefined) {
			props.onChange(e);
		} else if (props.onChange === "") {
			setSelectedValue(null);
		}
		setSelectedValue(e.target.value);
	};

	return (
		<div className="form-group mb-3">
			<div className="input-password">
				<label htmlFor={props.name} className="form-label">
					{props.label}
				</label>
				<input
					id={props.name}
					name={props.name}
					onPaste={(e)=>props.paste(e)}
					onCopy={(e)=>props.copy(e)}
					type={viewPassword ? "text" : "password"}
					{...register(props.name, {
						required: props.behavior.required,
						pattern: props.behavior.pattern,
						validate: props.behavior.validate
					})}
					className="form-control form-control-sm"
					onChange={e => onChange(e)}
					defaultValue={selectedValue || ""}
				/>
				<button
					className="view-password"
					type="button"
					onClick={() => setViewPassword(prevViewPassword => !prevViewPassword)}>
					{viewPassword ? <FaRegEyeSlash /> : <FaEye />}
				</button>
			</div>
			{props.behavior.required ? (
				<>
					<small className="form-text text-muted right" id={`${props.name}Error`}>
						<span className="required">{t("common.__required__")}</span>
					</small>
					{props.behavior.pattern && errors[props.name] && (
						<p className="error-msg mt-4">{t("users.__passwordWarning__")}</p>
					)}
				</>
			) : (
				props.behavior.pattern && errors[props.name] && <p className="error-msg mt-4">{t("users.__passwordWarning__")}</p>
			)}

			{props.behavior.validate && errors[props.name] && (
				<p className="error-msg mt-4">{t("login.__passwordVerification__")}</p>
			)}
		</div>
	);
};

export default MyInputPassword;
