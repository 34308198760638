import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaEye, FaRegEyeSlash, FaTimes } from "react-icons/fa";

import { HandleVersion } from "../layout/HandleVersion";
import { UserContext } from "../context/UserContext";
import Rgpd from "../components/UserSettings/Rgpd";
import * as p from "../constants/Permissions";
import * as url from "../constants/Url";
import http from "../services/Interceptor";
import i18next from "../i18n/i18next";
import Loader from "../layout/Loader";

interface FormValues {
	login: string;
	password: string;
}

const Login = (): JSX.Element => {
	const { t } = useTranslation("common");
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<FormValues>();
	const { userDispatch } = useContext(UserContext);
	const history = useHistory();
	const [show, setShow] = useState(false);
	const [viewPassword, setViewPassword] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const onSubmit = (data: FormValues): void => {
		setLoading(true);
		const loginData = {
			user: {
				login: data.login,
				password: data.password
			}
		};

		http
			.post(`${url.BASEURL}/login`, loginData)
			.then(res => {
				const user = res.data.user;
				setError(false);
				userDispatch({
					type: "LOGIN",
					payload: user
				});
				sessionStorage.setItem("isLogged", JSON.stringify(true));
				localStorage.setItem("firstName", JSON.stringify(user.first_name));
				localStorage.setItem("lastName", JSON.stringify(user.last_name));
				localStorage.setItem("type", JSON.stringify(user.type_id));
				localStorage.setItem("permissions", JSON.stringify(user.permissions));
				localStorage.setItem("homeId", JSON.stringify(user.home_id));
				localStorage.setItem("groupId", JSON.stringify(user.group_id));
				localStorage.setItem("tenantId", JSON.stringify(user.tenant_id));
				localStorage.setItem("id", JSON.stringify(user.id));
				localStorage.setItem("locale", JSON.stringify(user.locale));
				localStorage.setItem("timezone", JSON.stringify(user.timezone));
				const userPreferences = user.uiprefs ? user.uiprefs : null;
				localStorage.setItem("pref", JSON.stringify(userPreferences));

				if (res.data.user.locale === "fr") {
					localStorage.setItem("i18next", "fr-FR");
					i18next.changeLanguage("fr-FR");
				} else if (res.data.user.locale === "nl") {
					localStorage.setItem("i18next", "nl-BE");
					i18next.changeLanguage("nl-BE");
				} else {
					localStorage.setItem("i18next", "en-EN");
					i18next.changeLanguage("en-EN");
				}
				return user;
			})
			.then(user => {
				setLoading(false);
				if (user.permissions.includes(p.CONTROL_PANEL)) {
					history.push("/controlpanel/home");
				} else {
					if (!user.has_accepted_rgpd) {
						setShow(true);
					} else {
						if (user.home_id) {
							if (user.permissions.includes(p.DIAPER_APPLICATION)) {
								history.push("/homeboard");
							} else if (user.permissions.includes(p.CAN_MANAGE_HOME_UNIT)) {
								history.push("/manage/mainboard");
							} else {
								history.push("/blog");
							}
						} else if (user.group_id) {
							history.push(`/manage/organisation/myhomes`);
						} else if (user.permissions.includes(p.ADMIN)) {
							history.push("/homeboard");
						} else if (user.permissions.includes(p.SUPER_ADMIN)) {
							history.push("/support/tenant/admintenants");
						} else {
							history.push("/blog");
						}
					}
				}
			})
			.catch(() => {
				setLoading(false);
				setError(true);
			});
	};

	interface RgpdModalProps {
		show: boolean;
		setShow: (a: boolean) => void;
	}

	const RgpdModal = ({ show, setShow }: RgpdModalProps): JSX.Element | null => {
		return show ? (
			<div className="overlay">
				<div className="modal-rgpd">
					<p className="close-modal" role="button" onClick={(): void => setShow(false)}>
						<FaTimes />
					</p>
					<Rgpd setShow={setShow} />
				</div>
			</div>
		) : null;
	};

	return (
		<>
			<div className="login-container mx-auto text-center">
				{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
					<img src="/img/Logo-eforlink.png" alt="eForLink" className="login-logo mb-5" />
				) : (
					<img src="/img/amd/amdlink.png" alt="amd link" className="amd-login-logo mb-5" />
				)}

				{loading ? (
					<Loader />
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						{error ? <p className="error-msg">{t("login.__loginError__")}</p> : null}

						<div className="form-group">
							<label htmlFor="login">{t("common.__login__")}</label>
							<input
								type="text"
								className="form-control"
								id="login"
								aria-describedby="loginHelp"
								{...register("login", { required: true })}
								name="login"
							/>
							<small id="loginHelp" className="form-text text-muted">
								{errors.login && t("common.__required__")}
							</small>
						</div>
						<div className="form-group">
							<div className="input-password">
								<label htmlFor="password">{t("common.__password__")}</label>
								<input
									type={viewPassword ? "text" : "password"}
									className="form-control"
									id="password"
									{...register("password", { required: true })}
									name="password"
									aria-describedby="passwordHelp"
								/>
								<button
									className="view-password"
									type="button"
									onClick={(): void => setViewPassword(prevViewPassword => !prevViewPassword)}>
									{viewPassword ? <FaRegEyeSlash /> : <FaEye />}
								</button>
								<small id="emailHelp" className="form-text text-muted">
									{errors.password && t("common.__required__")}
								</small>
							</div>
						</div>
						<br />
						<Link to="/initpassword/dashboard">
							<p className="form-text button-text">{t("login.__lostPassword__")}</p>
						</Link>
						<br />
						<button type="submit" className="btn btn-primary">
							{t("users.__loginBtn__")}
						</button>
					</form>
				)}
			</div>
			<div className="app-version">{HandleVersion()}</div>
			<RgpdModal show={show} setShow={setShow} />
		</>
	);
};

export default Login;
