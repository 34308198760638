import i18n from "../../i18n/i18next";
import { SMILEY_HAPPY, SMILEY_RAS, SMILEY_SAD, SMILEY_VERY_HAPPY, SMILEY_VERY_SAD } from "./Smiley";

// ARRAY OF ALL ACTIONS -> to get settings for the FAMILY timeline
export const actionsByCategoryLists = [
	{
		name: i18n.t("activities:activities.meal"),
		actions: [
			100, 101, 102, 103, 104, 105, 106, 107, 108, 110, 111, 112, 113, 114, 115, 116, 117, 118, 120, 121, 122, 123, 124,
			125, 126, 127, 128, 130, 131, 132, 133, 134, 135, 136, 137, 138, 150, 151, 152, 153, 154, 155, 156, 157, 158
		]
	},
	{
		name: i18n.t("activities:activities.hydratation"),
		actions: [140, 141]
	},
	{
		name: i18n.t("activities:activities.wellness"),
		actions: [935, 350, 303, 300, 301, 302, 305]
	},
	{
		name: i18n.t("activities:activities.hygiene"),
		actions: [200, 201, 202, 210, 207, 208, 209, 205, 304, 221, 222, 206, 203, 211]
	},
	{
		name: i18n.t("activities:activities.housework"),
		actions: [204, 232, 231, 240, 241, 242, 250]
	},
	{
		name: i18n.t("activities:activities.care"),
		actions: [410, 411, 412, 421, 422, 431, 432, 440, 400, 401, 402, 403, 404, 450, 451, 452, 453, 454, 470]
	},
	{
		name: i18n.t("activities:activities.activities"),
		actions: [
			901, 902, 914, 915, 903, 904, 905, 916, 917, 918, 919, 910, 911, 912, 913, 924, 925, 920, 921, 922, 923, 926, 927,
			930, 931, 932, 933, 934, 936, 937, 15, 16, 17, 801, 802, 803, 804, 805, 806, 807
		]
	},
	{
		name: i18n.t("activities:activities.medicalCare"),
		actions: [
			600, 601, 540, 500, 550, 551, 552, 553, 554, 570, 581, 590, 580, 560, 561, 582, 1500, 1550, 1551, 1552, 1553, 1554,
			1570, 1581, 1590, 1580, 1560, 1561
		]
	},
	{
		name: i18n.t("activities:activities.emergency"),
		actions: [2000, 2001, 2010, 2011]
	},
	{
		name: i18n.t("activities:activities.visits"),
		actions: [1000, 1001, 1002]
	}
];

export const mood = (o: number): string | null => {
	switch (o) {
		case SMILEY_VERY_SAD:
			return i18n.t("activities:activities.veryBadMood");
		case SMILEY_SAD:
			return i18n.t("activities:activities.badMood");
		case SMILEY_RAS:
			return i18n.t("activities:activities.ras");
		case SMILEY_HAPPY:
			return i18n.t("activities:activities.goodMood");
		case SMILEY_VERY_HAPPY:
			return i18n.t("activities:activities.veryGoodMood");
		default:
			return null;
	}
};

// Virtual action built when HealthWorker does not make action but only Connect -> Smiley -> Disconnect.
const PURE_CHECK = 10;

// System
const CONNECTION = 11;
const DISCONNECTION = 12;
const FAMILY_USER_QRCODE_VISIT = 13;
const MEMO_T_ADDED = 14;
const SENIOR_MESSAGE_READ = 15;
const SENIOR_MESSAGE_REPLY = 16;
const SENIOR_STARTS_VISIO = 17;
const UNKNOWN_QRCODE = 98;
const NOOP = 99;

// Actions
const BREAKFAST_ROOM_BRING = 100;
const BREAKFAST_ROOM_BACK = 101;
const BREAKFAST_BED_BRING = 102;
const BREAKFAST_BED_BACK = 103;
const BREAKFAST_DINING_HALL = 104;
const BREAKFAST_EXTERNAL = 105;
const BREAKFAST_ROOM_HELP = 106;
const BREAKFAST_BED_HELP = 107;
const BREAKFAST_ROOM_INSTALL = 108;

const LUNCH_ROOM_BRING = 110;
const LUNCH_ROOM_BACK = 111;
const LUNCH_BED_BRING = 112;
const LUNCH_BED_BACK = 113;
const LUNCH_DINING_HALL = 114;
const LUNCH_EXTERNAL = 115;
const LUNCH_ROOM_HELP = 116;
const LUNCH_BED_HELP = 117;
const LUNCH_ROOM_INSTALL = 118;

const SNACK_ROOM_BRING = 120;
const SNACK_ROOM_BACK = 121;
const SNACK_BED_BRING = 122;
const SNACK_BED_BACK = 123;
const SNACK_DINING_HALL = 124;
const SNACK_EXTERNAL = 125;
const SNACK_ROOM_HELP = 126;
const SNACK_BED_HELP = 127;
const SNACK_ROOM_INSTALL = 128;

const DINNER_ROOM_BRING = 130;
const DINNER_ROOM_BACK = 131;
const DINNER_BED_BRING = 132;
const DINNER_BED_BACK = 133;
const DINNER_DINING_HALL = 134;
const DINNER_EXTERNAL = 135;
const DINNER_ROOM_HELP = 136;
const DINNER_BED_HELP = 137;
const DINNER_ROOM_INSTALL = 138;

const HYDRATION = 140;
const WATER_JUG_DISTRIBUTION = 141;

const LIGHT_SNACK_ROOM_BRING = 150;
const LIGHT_SNACK_ROOM_BACK = 151;
const LIGHT_SNACK_BED_BRING = 152;
const LIGHT_SNACK_BED_BACK = 153;
const LIGHT_SNACK_DINING_HALL = 154;
const LIGHT_SNACK_EXTERNAL = 155;
const LIGHT_SNACK_ROOM_HELP = 156;
const LIGHT_SNACK_BED_HELP = 157;
const LIGHT_SNACK_ROOM_INSTALL = 158;

const SHOWER = 200;
const SPONGE_BATH = 201;
const BATH = 202;
const BED_SHEETS = 203;
const HOUSE_WORK = 204;
const MANICURE = 205;
const HELP_DRESSING = 206;
const TEETH_BRUSH = 207;
const SHAVING = 208;
const CREAM = 209;

const HELP_WASH = 210;
const MAKE_BED = 211;

const DIAPER_CHANGE = 221;
const DIAPER_CONTROL = 222;
const HANDLING_REPAIR = 231;
const HANDLING_CONTROL = 232;
const LAUNDRY_BRING = 240;
const LAUNDRY_BACK = 241;

const LAUNDRY_CLOTHES = 242;

const DISINFECTION = 250;

const HAIRDRESSER = 300;
const BEAUTICIAN = 301;
const BEAUTICIAN_MANICURE = 302;
const PET_THERAPY = 303;
const DENTAL_APP_MAINTENANCE = 304;
const TAPPING_MASSAGE = 305;
const SNOEZELEN = 350;

const TEMPERATURE = 400;
const OXYMETER = 401;
const BLOOD_PRESSURE = 402;
const ECG = 403;
const WEIGHT = 404;

const MEDS_DISTRIBUTION = 410;
const MEDS_DELIVERY = 411;
const MEDS_CONTROL = 412;
const DRESSING = 421;
const DRESSING_CONTROL = 422;
const PERFUSION = 431;
const PERFUSION_CONTROL = 432;
const PREVENTION_BEDSORE = 440;

const HELP_WAKE_UP = 450;
const HELP_BEDTIME = 451;
const HELP_ESCORT_NAP = 452;
const HELP_ESCORT_ACTIVITY = 453;
const HELP_ESCORT_ROOM_OUT = 454;
const ANSWER_CALL_BELL = 470;

const DOCTOR = 500;
const SUPERVISOR = 540;
const DENTIST = 550;
const CHIROPODIST = 551;
const PHARMACIST = 552;
const ORTHOPEDICS = 553;
const PSYCHOLOGIST = 554;
const AUDIO = 560;
const DIETITIAN = 561;
const OPTICIAN = 570;
const PHYSIOTHERAPIST = 580;
const PSYCHOMOTRICIAN = 581;
const SPEECH_THERAPIST = 582;
const OCCUPATIONAL_THERAPIST = 590;

const VISIT_CONTROL = 600;
const VISIT_RELATIONAL = 601;

const OTHER_PHYSICAL = 801;
const OTHER_INTELLECTUAL = 802;
const OTHER_CULTURAL = 803;
const OTHER_MANUAL = 804;
const OTHER_SOCIAL = 805;
const OTHER_WELL_BEING = 806;
const OTHER_MISCELLANEOUS = 807;

const SOFT_GYM = 901;
const WORKSHOP_BALANCE = 902;
const WORKSHOP_MEMORY = 903;
const CHAT = 904;
const QUIZZ = 905;
const VISIT = 910;
const SLIDESHOW = 911;
const SHOW = 912;
const THEATER = 913;

const PETANQUE = 914;
const PROMENADE = 915;
const READING = 916;
const DIGITAL_GAMING = 917;
const PETIT_BAC = 918;
const BINGO = 919;

const MODELING = 920;
const GARDENING = 921;
const COOKING = 922;
const DIY = 923;

const MUSIC_SONG = 924;
const MOVIE_THEATER = 925;
const SEWING_KNITTING = 926;
const CREATIVE_WORKSHOP = 927;

const INTERGENERATIONAL_MEETING = 930;
const CORRESPONDENCE = 931;

const THEMED_MEAL = 932;
const THEMED_EVENT = 933;
const ANIMATED_SNACK = 934;
const WELLNESS_WORKSHOP = 935;
const WORDS_NUMBERS_GAMES = 936;
const WORSHIP = 937;

const FAMILY = 1000;
const LEGAL_GUARDIAN = 1001;
const ACCOMPANYING_PERSON = 1002;

const EXTERNAL_DOCTOR = 1500;
const EXTERNAL_DENTIST = 1550;
const EXTERNAL_CHIROPODIST = 1551;
const EXTERNAL_PHARMACIST = 1552;
const EXTERNAL_ORTHOPEDICS = 1553;
const EXTERNAL_PSYCHOLOGIST = 1554;
const EXTERNAL_AUDIO = 1560;
const EXTERNAL_DIETITIAN = 1561;
const EXTERNAL_OPTICIAN = 1570;
const EXTERNAL_PHYSIOTHERAPIST = 1580;
const EXTERNAL_PSYCHOMOTRICIAN = 1581;
const EXTERNAL_OCCUPATIONAL_THERAPIST = 1590;

const EMERGENCY_AUTO_LIFT_REMOVAL_JUSTIFIED = 2000;
const EMERGENCY_AUTO_LIFT_REMOVAL_INJUSTIFIED = 2001;
const EMERGENCY_MANUAL_LIFT_REMOVAL_JUSTIFIED = 2010;
const EMERGENCY_MANUAL_LIFT_REMOVAL_INJUSTIFIED = 2011;

export function getActivityTitle(aid: number): string {
	if (
		(aid >= BREAKFAST_ROOM_BRING && aid <= DINNER_ROOM_INSTALL) ||
		(aid >= LIGHT_SNACK_ROOM_BRING && aid <= LIGHT_SNACK_ROOM_INSTALL)
	) {
		return i18n.t("activities:activities.meal");
	} else if (
		(aid >= HAIRDRESSER && aid <= PET_THERAPY) ||
		aid === TAPPING_MASSAGE ||
		aid === WELLNESS_WORKSHOP ||
		aid === SNOEZELEN
	) {
		return i18n.t("activities:activities.wellness");
	} else if (aid >= TEMPERATURE && aid <= ANSWER_CALL_BELL) {
		return i18n.t("activities:activities.care");
	} else if ((aid >= FAMILY && aid <= ACCOMPANYING_PERSON) || aid === FAMILY_USER_QRCODE_VISIT) {
		return i18n.t("activities:activities.visits");
	} else if (
		(aid >= EXTERNAL_DOCTOR && aid <= EXTERNAL_OCCUPATIONAL_THERAPIST) ||
		(aid >= DOCTOR && aid <= VISIT_RELATIONAL) ||
		aid === MEMO_T_ADDED
	) {
		return i18n.t("activities:activities.medicalCare");
	} else if (aid >= EMERGENCY_AUTO_LIFT_REMOVAL_JUSTIFIED && aid <= EMERGENCY_MANUAL_LIFT_REMOVAL_INJUSTIFIED) {
		return i18n.t("activities:activities.emergency");
	} else if (
		(aid >= OTHER_PHYSICAL && aid <= OTHER_MISCELLANEOUS) ||
		(aid >= SOFT_GYM && aid <= WORSHIP) ||
		(aid >= SENIOR_MESSAGE_READ && aid <= SENIOR_STARTS_VISIO)
	) {
		return i18n.t("activities:activities.activities");
	} else if (
		(aid >= SHOWER && aid <= BED_SHEETS) ||
		(aid >= MANICURE && aid <= DIAPER_CONTROL) ||
		aid === DENTAL_APP_MAINTENANCE
	) {
		return i18n.t("activities:activities.hygiene");
	} else if (aid === HOUSE_WORK || (aid >= HANDLING_REPAIR && aid <= DISINFECTION)) {
		return i18n.t("activities:activities.housework");
	} else if (aid === HYDRATION || aid === WATER_JUG_DISTRIBUTION) {
		return i18n.t("activities:activities.hydratation");
	} else {
		return i18n.t("activities:activities.visit");
	}
}

export function getCategoryClassName(aid: number): string {
	if (
		(aid >= BREAKFAST_ROOM_BRING && aid <= DINNER_ROOM_INSTALL) ||
		(aid >= LIGHT_SNACK_ROOM_BRING && aid <= LIGHT_SNACK_ROOM_INSTALL)
	) {
		return "nutrition";
	} else if (
		(aid >= HAIRDRESSER && aid <= PET_THERAPY) ||
		aid === TAPPING_MASSAGE ||
		aid === WELLNESS_WORKSHOP ||
		aid === SNOEZELEN
	) {
		return "beauty";
	} else if (aid >= TEMPERATURE && aid <= ANSWER_CALL_BELL) {
		return "care";
	} else if ((aid >= FAMILY && aid <= ACCOMPANYING_PERSON) || aid === FAMILY_USER_QRCODE_VISIT) {
		return "visit";
	} else if (
		(aid >= EXTERNAL_DOCTOR && aid <= EXTERNAL_OCCUPATIONAL_THERAPIST) ||
		(aid >= DOCTOR && aid <= VISIT_RELATIONAL) ||
		aid === MEMO_T_ADDED
	) {
		return "medecine";
	} else if (aid >= EMERGENCY_AUTO_LIFT_REMOVAL_JUSTIFIED && aid <= EMERGENCY_MANUAL_LIFT_REMOVAL_INJUSTIFIED) {
		return "emergency";
	} else if (
		(aid >= OTHER_PHYSICAL && aid <= OTHER_MISCELLANEOUS) ||
		(aid >= SOFT_GYM && aid <= WORSHIP) ||
		(aid >= SENIOR_MESSAGE_READ && aid <= SENIOR_STARTS_VISIO)
	) {
		return "activity";
	} else if (
		(aid >= SHOWER && aid <= BED_SHEETS) ||
		(aid >= MANICURE && aid <= DIAPER_CONTROL) ||
		aid === DENTAL_APP_MAINTENANCE
	) {
		return "hygiene";
	} else if (aid === HYDRATION || aid === WATER_JUG_DISTRIBUTION) {
		return "hydratation";
	} else {
		return "mood";
	}
}

export function getActivityAction(aid: number): string {
	switch (aid) {
		case SMILEY_VERY_SAD:
			return i18n.t("activities:activities.veryBadMood");
		case SMILEY_SAD:
			return i18n.t("activities:activities.badMood");
		case SMILEY_RAS:
			return i18n.t("activities:activities.ras");
		case SMILEY_HAPPY:
			return i18n.t("activities:activities.goodMood");
		case SMILEY_VERY_HAPPY:
			return i18n.t("activities:activities.veryGoodMood");
		case PURE_CHECK:
			return i18n.t("activities:activities.passageForControl");
		case CONNECTION:
			return "Connexion";
		case DISCONNECTION:
			return "Déconnexion";
		case FAMILY_USER_QRCODE_VISIT:
			return i18n.t("activities:activities.familyQRcode");
		case UNKNOWN_QRCODE:
			return i18n.t("common:activities.__badgeUnknown__");
		case NOOP:
			return i18n.t("common:activities.__other__");
		case MEMO_T_ADDED:
			return i18n.t("common:memo.__memo__");

		case BREAKFAST_ROOM_BRING:
			return i18n.t("activities:activities.breakfast") + " : " + i18n.t("activities:activities.inRoom");
		case BREAKFAST_ROOM_BACK:
			return i18n.t("activities:activities.breakfast") + " : " + i18n.t("activities:activities.backTray");
		case BREAKFAST_BED_BRING:
			return i18n.t("activities:activities.breakfast") + " : " + i18n.t("activities:activities.inBed");
		case BREAKFAST_BED_BACK:
			return i18n.t("activities:activities.breakfast") + " : " + i18n.t("activities:activities.backTray");
		case BREAKFAST_DINING_HALL:
			return i18n.t("activities:activities.breakfast") + " : " + i18n.t("activities:activities.inTheRefectory");
		case BREAKFAST_EXTERNAL:
			return i18n.t("activities:activities.breakfast") + " : " + i18n.t("activities:activities.outside");

		case BREAKFAST_ROOM_HELP:
			return (
				i18n.t("activities:activities.breakfast") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.help")
			);
		case BREAKFAST_ROOM_INSTALL:
			return (
				i18n.t("activities:activities.breakfast") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.helpInstall")
			);
		case BREAKFAST_BED_HELP:
			return (
				i18n.t("activities:activities.breakfast") +
				" : " +
				i18n.t("activities:activities.inBed") +
				" : " +
				i18n.t("activities:activities.help")
			);

		case LUNCH_ROOM_BRING:
			return i18n.t("activities:activities.lunch") + " : " + i18n.t("activities:activities.inRoom");
		case LUNCH_ROOM_BACK:
			return i18n.t("activities:activities.lunch") + " : " + i18n.t("activities:activities.backTray");
		case LUNCH_BED_BRING:
			return i18n.t("activities:activities.lunch") + " : " + i18n.t("activities:activities.inBed");
		case LUNCH_BED_BACK:
			return i18n.t("activities:activities.lunch") + " : " + i18n.t("activities:activities.backTray");
		case LUNCH_DINING_HALL:
			return i18n.t("activities:activities.lunch") + " : " + i18n.t("activities:activities.inTheRefectory");
		case LUNCH_EXTERNAL:
			return i18n.t("activities:activities.lunch") + " : " + i18n.t("activities:activities.outside");

		case LUNCH_ROOM_HELP:
			return (
				i18n.t("activities:activities.lunch") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.help")
			);
		case LUNCH_ROOM_INSTALL:
			return (
				i18n.t("activities:activities.lunch") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.helpInstall")
			);
		case LUNCH_BED_HELP:
			return (
				i18n.t("activities:activities.lunch") +
				" : " +
				i18n.t("activities:activities.inBed") +
				" : " +
				i18n.t("activities:activities.help")
			);

		case SNACK_ROOM_BRING:
			return i18n.t("activities:activities.snack") + " : " + i18n.t("activities:activities.inRoom");
		case SNACK_ROOM_BACK:
			return i18n.t("activities:activities.snack") + " : " + i18n.t("activities:activities.backTray");
		case SNACK_BED_BRING:
			return i18n.t("activities:activities.snack") + " : " + i18n.t("activities:activities.inBed");
		case SNACK_BED_BACK:
			return i18n.t("activities:activities.snack") + " : " + i18n.t("activities:activities.backTray");
		case SNACK_DINING_HALL:
			return i18n.t("activities:activities.snack") + " : " + i18n.t("activities:activities.inTheRefectory");
		case SNACK_EXTERNAL:
			return i18n.t("activities:activities.snack") + " : " + i18n.t("activities:activities.outside");

		case SNACK_ROOM_HELP:
			return (
				i18n.t("activities:activities.snack") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.help")
			);
		case SNACK_ROOM_INSTALL:
			return (
				i18n.t("activities:activities.snack") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.helpInstall")
			);
		case SNACK_BED_HELP:
			return (
				i18n.t("activities:activities.snack") +
				" : " +
				i18n.t("activities:activities.inBed") +
				" : " +
				i18n.t("activities:activities.help")
			);

		case DINNER_ROOM_BRING:
			return i18n.t("activities:activities.dinner") + " : " + i18n.t("activities:activities.inRoom");
		case DINNER_ROOM_BACK:
			return i18n.t("activities:activities.dinner") + " : " + i18n.t("activities:activities.backTray");
		case DINNER_BED_BRING:
			return i18n.t("activities:activities.dinner") + " : " + i18n.t("activities:activities.inBed");
		case DINNER_BED_BACK:
			return i18n.t("activities:activities.dinner") + " : " + i18n.t("activities:activities.backTray");
		case DINNER_DINING_HALL:
			return i18n.t("activities:activities.dinner") + " : " + i18n.t("activities:activities.inTheRefectory");
		case DINNER_EXTERNAL:
			return i18n.t("activities:activities.dinner") + " : " + i18n.t("activities:activities.outside");

		case DINNER_ROOM_HELP:
			return (
				i18n.t("activities:activities.dinner") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.help")
			);
		case DINNER_ROOM_INSTALL:
			return (
				i18n.t("activities:activities.dinner") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.helpInstall")
			);
		case DINNER_BED_HELP:
			return (
				i18n.t("activities:activities.dinner") +
				" : " +
				i18n.t("activities:activities.inBed") +
				" : " +
				i18n.t("activities:activities.help")
			);

		case LIGHT_SNACK_ROOM_BRING:
			return i18n.t("activities:activities.lightSnack") + " : " + i18n.t("activities:activities.inRoom");
		case LIGHT_SNACK_ROOM_BACK:
			return i18n.t("activities:activities.lightSnack") + " : " + i18n.t("activities:activities.backTray");
		case LIGHT_SNACK_BED_BRING:
			return i18n.t("activities:activities.lightSnack") + " : " + i18n.t("activities:activities.inBed");
		case LIGHT_SNACK_BED_BACK:
			return i18n.t("activities:activities.lightSnack") + " : " + i18n.t("activities:activities.backTray");
		case LIGHT_SNACK_DINING_HALL:
			return i18n.t("activities:activities.lightSnack") + " : " + i18n.t("activities:activities.inTheRefectory");
		case LIGHT_SNACK_EXTERNAL:
			return i18n.t("activities:activities.lightSnack") + " : " + i18n.t("activities:activities.outside");

		case LIGHT_SNACK_ROOM_HELP:
			return (
				i18n.t("activities:activities.lightSnack") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.help")
			);
		case LIGHT_SNACK_ROOM_INSTALL:
			return (
				i18n.t("activities:activities.lightSnack") +
				" : " +
				i18n.t("activities:activities.inRoom") +
				" : " +
				i18n.t("activities:activities.helpInstall")
			);
		case LIGHT_SNACK_BED_HELP:
			return (
				i18n.t("activities:activities.lightSnack") +
				" : " +
				i18n.t("activities:activities.inBed") +
				" : " +
				i18n.t("activities:activities.help")
			);

		case HYDRATION:
			return i18n.t("activities:activities.hydratation");
		case WATER_JUG_DISTRIBUTION:
			return i18n.t("activities:activities.waterDelivery");

		case HELP_WAKE_UP:
			return i18n.t("activities:activities.helpWakeUp");
		case HELP_BEDTIME:
			return i18n.t("activities:activities.helpBedTime");
		case HELP_ESCORT_NAP:
			return i18n.t("activities:activities.helpNap");
		case HELP_ESCORT_ACTIVITY:
			return i18n.t("activities:activities.helpActivity");
		case HELP_ESCORT_ROOM_OUT:
			return i18n.t("activities:activities.helpRoomOut");
		case HELP_WASH:
			return i18n.t("activities:activities.helpWash");
		case ANSWER_CALL_BELL:
			return i18n.t("activities:activities.answerBell");
		case MAKE_BED:
			return i18n.t("activities:activities.makeBed");
		case LAUNDRY_CLOTHES:
			return i18n.t("activities:activities.laundryClothes");
		case VISIT_CONTROL:
			return i18n.t("activities:activities.visitControl");
		case VISIT_RELATIONAL:
			return i18n.t("activities:activities.visitRelational");
		case SHOWER:
			return i18n.t("activities:activities.shower");
		case SPONGE_BATH:
			return i18n.t("activities:activities.spongeBath");
		case BATH:
			return i18n.t("activities:activities.bath");
		case BED_SHEETS:
			return i18n.t("activities:activities.bedding");
		case HOUSE_WORK:
			return i18n.t("activities:activities.housework");
		case MANICURE:
			return i18n.t("activities:activities.hygienicManicure");
		case HELP_DRESSING:
			return i18n.t("activities:activities.clothing");
		case TEETH_BRUSH:
			return i18n.t("activities:activities.teethBrush");
		case SHAVING:
			return i18n.t("activities:activities.shaving");
		case CREAM:
			return i18n.t("activities:activities.lotionCream");

		case DIAPER_CHANGE:
			return i18n.t("activities:activities.diaper") + " : " + i18n.t("activities:activities.change");
		case DIAPER_CONTROL:
			return i18n.t("activities:activities.diaper") + " : " + i18n.t("activities:activities.control");
		case HANDLING_REPAIR:
			return i18n.t("activities:activities.handling") + " : " + i18n.t("activities:activities.repairs");
		case HANDLING_CONTROL:
			return i18n.t("activities:activities.handling") + " : " + i18n.t("activities:activities.control");
		case LAUNDRY_BRING:
			return i18n.t("activities:activities.laundry") + " : " + i18n.t("activities:activities.deliver");
		case LAUNDRY_BACK:
			return i18n.t("activities:activities.laundry") + " : " + i18n.t("activities:activities.remove");
		case DISINFECTION:
			return i18n.t("activities:activities.disinfection");

		case HAIRDRESSER:
			return i18n.t("activities:activities.hairDresser");
		case BEAUTICIAN:
			return i18n.t("activities:activities.beautician");
		case BEAUTICIAN_MANICURE:
			return i18n.t("activities:activities.beauticianManicure");
		case PET_THERAPY:
			return i18n.t("activities:activities.petTherapy");
		case DENTAL_APP_MAINTENANCE:
			return i18n.t("activities:activities.dentalAppliance");
		case SNOEZELEN:
			return i18n.t("activities:activities.snoezelen");

		case TEMPERATURE:
			return i18n.t("activities:activities.temperature");
		case OXYMETER:
			return i18n.t("activities:activities.oximeter");
		case BLOOD_PRESSURE:
			return i18n.t("activities:activities.bloodPressure");
		case ECG:
			return i18n.t("activities:activities.electrocardiogram");
		case WEIGHT:
			return i18n.t("activities:activities.weight");

		case MEDS_DISTRIBUTION:
			return i18n.t("activities:activities.medecine") + " : " + i18n.t("activities:activities.distribution");
		case MEDS_DELIVERY:
			return i18n.t("activities:activities.medecine") + " : " + i18n.t("activities:activities.administration");
		case MEDS_CONTROL:
			return i18n.t("activities:activities.medecine") + " : " + i18n.t("activities:activities.control");
		case DRESSING:
			return i18n.t("activities:activities.dressing") + " : " + i18n.t("activities:activities.installChange");
		case DRESSING_CONTROL:
			return i18n.t("activities:activities.dressing") + " : " + i18n.t("activities:activities.control");
		case PERFUSION:
			return i18n.t("activities:activities.perfusion") + " : " + i18n.t("activities:activities.installChange");
		case PERFUSION_CONTROL:
			return i18n.t("activities:activities.perfusion") + " : " + i18n.t("activities:activities.control");
		case PREVENTION_BEDSORE:
			return i18n.t("activities:activities.bedsorePrevention");

		case DOCTOR:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.doctor")
			);
		case SUPERVISOR:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.healthManager")
			);
		case DENTIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.dentist")
			);
		case CHIROPODIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.chiropodist")
			);
		case PHARMACIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.pharmacist")
			);
		case ORTHOPEDICS:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.orthopedist")
			);
		case PSYCHOLOGIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.psychologist")
			);
		case AUDIO:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.audioprosthetist")
			);
		case DIETITIAN:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.dietitian")
			);
		case OPTICIAN:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.optician")
			);
		case PHYSIOTHERAPIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.physiotherapist")
			);
		case PSYCHOMOTRICIAN:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.psychomotrician")
			);
		case OCCUPATIONAL_THERAPIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.occupationalTherapist")
			);
		case SPEECH_THERAPIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.internal") +
				" : " +
				i18n.t("activities:activities.speechTherapist")
			);
		case SOFT_GYM:
			return i18n.t("activities:activities.softGym");
		case WORKSHOP_BALANCE:
			return i18n.t("activities:activities.workshopBalance");
		case WORKSHOP_MEMORY:
			return i18n.t("activities:activities.workshopMemory");
		case CHAT:
			return i18n.t("activities:activities.chat");
		case QUIZZ:
			return i18n.t("activities:activities.quizz");
		case VISIT:
			return i18n.t("activities:activities.visit");
		case SLIDESHOW:
			return i18n.t("activities:activities.slideshow");
		case SHOW:
			return i18n.t("activities:activities.show");
		case THEATER:
			return i18n.t("activities:activities.theater");
		case MODELING:
			return i18n.t("activities:activities.modeling");
		case GARDENING:
			return i18n.t("activities:activities.gardening");
		case COOKING:
			return i18n.t("activities:activities.cooking");
		case DIY:
			return i18n.t("activities:activities.diy");
		case INTERGENERATIONAL_MEETING:
			return i18n.t("activities:activities.intergenerationalMeeting");
		case CORRESPONDENCE:
			return i18n.t("activities:activities.correspondence");

		case PETANQUE:
			return i18n.t("activities:activities.petanque");
		case PROMENADE:
			return i18n.t("activities:activities.promenade");
		case READING:
			return i18n.t("activities:activities.reading");
		case DIGITAL_GAMING:
			return i18n.t("activities:activities.digitalGaming");
		case PETIT_BAC:
			return i18n.t("activities:activities.petitBac");
		case BINGO:
			return i18n.t("activities:activities.bingo");
		case MUSIC_SONG:
			return i18n.t("activities:activities.musicSong");
		case MOVIE_THEATER:
			return i18n.t("activities:activities.cinema");
		case SEWING_KNITTING:
			return i18n.t("activities:activities.sewing");
		case CREATIVE_WORKSHOP:
			return i18n.t("activities:activities.creativeWorkshop");
		case THEMED_EVENT:
			return i18n.t("activities:activities.themedEvent");
		case THEMED_MEAL:
			return i18n.t("activities:activities.themedMeal");
		case ANIMATED_SNACK:
			return i18n.t("activities:activities.animatedSnack");

		case WELLNESS_WORKSHOP:
			return i18n.t("activities:activities.wellnessWorkshop");
		case FAMILY:
			return i18n.t("activities:activities.visitFromFamilyMember");
		case LEGAL_GUARDIAN:
			return i18n.t("activities:activities.visit") + " : " + i18n.t("activities:activities.legalGuardian");
		case ACCOMPANYING_PERSON:
			return i18n.t("activities:activities.accompagnyingPerson");

		case EXTERNAL_DOCTOR:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.doctor")
			);
		case EXTERNAL_DENTIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.dentist")
			);
		case EXTERNAL_CHIROPODIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.chiropodist")
			);
		case EXTERNAL_PHARMACIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.pharmacist")
			);
		case EXTERNAL_ORTHOPEDICS:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.orthopedist")
			);
		case EXTERNAL_PSYCHOLOGIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.psychologist")
			);
		case EXTERNAL_AUDIO:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.audioprosthetist")
			);
		case EXTERNAL_DIETITIAN:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.dietitian")
			);
		case EXTERNAL_OPTICIAN:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.optician")
			);
		case EXTERNAL_PHYSIOTHERAPIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.physiotherapist")
			);
		case EXTERNAL_PSYCHOMOTRICIAN:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.psychomotrician")
			);
		case EXTERNAL_OCCUPATIONAL_THERAPIST:
			return (
				i18n.t("activities:activities.visit") +
				" " +
				i18n.t("activities:activities.external") +
				" : " +
				i18n.t("activities:activities.occupationalTherapist")
			);
		case EMERGENCY_AUTO_LIFT_REMOVAL_JUSTIFIED:
			return (
				i18n.t("activities:activities.emergencyAutomatic") +
				" : " +
				i18n.t("activities:activities.removeDoubts") +
				" " +
				i18n.t("activities:activities.justified")
			);
		case EMERGENCY_AUTO_LIFT_REMOVAL_INJUSTIFIED:
			return (
				i18n.t("activities:activities.emergencyAutomatic") +
				" : " +
				i18n.t("activities:activities.removeDoubts") +
				" " +
				i18n.t("activities:activities.unjustified")
			);
		case EMERGENCY_MANUAL_LIFT_REMOVAL_JUSTIFIED:
			return (
				i18n.t("activities:activities.emergencyManually") +
				" : " +
				i18n.t("activities:activities.removeDoubts") +
				" " +
				i18n.t("activities:activities.justified")
			);
		case EMERGENCY_MANUAL_LIFT_REMOVAL_INJUSTIFIED:
			return (
				i18n.t("activities:activities.emergencyManually") +
				" : " +
				i18n.t("activities:activities.removeDoubts") +
				" " +
				i18n.t("activities:activities.unjustified")
			);
		case SENIOR_STARTS_VISIO:
			return i18n.t("activities:activities.liveMeeting");
		case SENIOR_MESSAGE_REPLY:
			return i18n.t("activities:activities.answerFamily");

		case TAPPING_MASSAGE:
			return i18n.t("activities:activities.tappingMassage");
		case WORDS_NUMBERS_GAMES:
			return i18n.t("activities:activities.wordsNumbersGames");
		case WORSHIP:
			return i18n.t("activities:activities.worship");
		case OTHER_PHYSICAL:
			return i18n.t("activities:activities.physicalActivity");
		case OTHER_INTELLECTUAL:
			return i18n.t("activities:activities.intellectualActivity");
		case OTHER_CULTURAL:
			return i18n.t("activities:activities.culturalActivity");
		case OTHER_MANUAL:
			return i18n.t("activities:activities.manualActivity");
		case OTHER_SOCIAL:
			return i18n.t("activities:activities.socialActivity");
		case OTHER_WELL_BEING:
			return i18n.t("activities:activities.wellbeingActivity");
		case OTHER_MISCELLANEOUS:
			return i18n.t("activities:activities.otherActivity");

		default:
			return i18n.t("activities:activities.visit");
	}
}

export function getActivityDetail(dval: number, activity: number): string | undefined {
	switch (activity) {
		case BREAKFAST_ROOM_BACK:
		case BREAKFAST_BED_BACK:
		case LUNCH_ROOM_BACK:
		case LUNCH_BED_BACK:
		case SNACK_ROOM_BACK:
		case SNACK_BED_BACK:
		case DINNER_ROOM_BACK:
		case DINNER_BED_BACK:
			if (dval === 0) {
				return i18n.t("activities:activities.hadAGoodApppetite");
			} else if (dval === 1) {
				return i18n.t("activities:activities.correctAppetite");
			} else if (dval === 2) {
				return i18n.t("activities:activities.lackOfAppetite");
			}
			break;
		case BREAKFAST_DINING_HALL:
		case LUNCH_DINING_HALL:
		case SNACK_DINING_HALL:
		case DINNER_DINING_HALL:
			if (dval === 0) {
				return i18n.t("activities:activities.toMonitor");
			} else if (dval === 1) {
				return i18n.t("activities:activities.perfect");
			}
			break;
		case HYDRATION:
			if (dval === 0) {
				return i18n.t("activities:activities.perfect");
			} else if (dval === 1) {
				return i18n.t("activities:activities.toMonitor");
			} else if (dval === 2) {
				return i18n.t("activities:activities.insufficient");
			}
			break;
		case DIAPER_CHANGE:
		case DIAPER_CONTROL:
			if (dval === -1) {
				return "";//i18n.t("common:diaper.__noDiaper__")
			}
			break;
		case PHYSIOTHERAPIST:
		case PSYCHOMOTRICIAN:
		case OCCUPATIONAL_THERAPIST:
		case EXTERNAL_PHYSIOTHERAPIST:
		case EXTERNAL_PSYCHOMOTRICIAN:
		case EXTERNAL_OCCUPATIONAL_THERAPIST:
			if (dval === 0) {
				return i18n.t("activities:activities.toMonitor");
			} else if (dval === 1) {
				return i18n.t("activities:activities.correct");
			}
			break;
		default:
			return;
	}
}

// Elements necessary for Occupation Selection
// Used to display in Family when resident participates in an activity

const physicalActivities = [
	{
		value: 901,
		label: i18n.t("activities:activities.softGym")
	},
	{
		value: 902,
		label: i18n.t("activities:activities.workshopBalance")
	},
	{
		value: 914,
		label: i18n.t("activities:activities.petanque")
	},
	{
		value: 915,
		label: i18n.t("activities:activities.promenade")
	},
	{
		value: 801,
		label: `${i18n.t("activities:activities.other")} ${i18n.t("activities:activities.physicalActivity")}`
	}
];

const culturalActivities = [
	{
		value: 910,
		label: i18n.t("activities:activities.visit")
	},
	{
		value: 911,
		label: i18n.t("activities:activities.slideshow")
	},
	{
		value: 912,
		label: i18n.t("activities:activities.show")
	},
	{
		value: 913,
		label: i18n.t("activities:activities.theater")
	},
	{
		value: 924,
		label: i18n.t("activities:activities.musicSong")
	},
	{
		value: 925,
		label: i18n.t("activities:activities.cinema")
	},
	{
		value: 803,
		label: `${i18n.t("activities:activities.other")} ${i18n.t("activities:activities.culturalActivity")}`
	}
];

const socialActivities = [
	{
		value: 930,
		label: i18n.t("activities:activities.intergenerationalMeeting")
	},
	{
		value: 931,
		label: i18n.t("activities:activities.correspondence")
	},
	{
		value: 932,
		label: i18n.t("activities:activities.themedMeal")
	},
	{
		value: 933,
		label: i18n.t("activities:activities.themedEvent")
	},
	{
		value: 934,
		label: i18n.t("activities:activities.animatedSnack")
	},
	{
		value: 805,
		label: `${i18n.t("activities:activities.other")} ${i18n.t("activities:activities.socialActivity")}`
	}
];

const intellectualActivity = [
	{
		value: 903,
		label: i18n.t("activities:activities.workshopMemory")
	},
	{
		value: 905,
		label: i18n.t("activities:activities.quizz")
	},
	{
		value: 917,
		label: i18n.t("activities:activities.digitalGaming")
	},
	{
		value: 919,
		label: i18n.t("activities:activities.bingo")
	},
	{
		value: 904,
		label: i18n.t("activities:activities.chat")
	},
	{
		value: 916,
		label: i18n.t("activities:activities.reading")
	},
	{
		value: 918,
		label: i18n.t("activities:activities.petitBac")
	},
	{
		value: 936,
		label: i18n.t("activities:activities.wordsNumbersGames")
	},
	{
		value: 802,
		label: `${i18n.t("activities:activities.other")} ${i18n.t("activities:activities.intellectualActivity")}`
	}
];

const manualActivities = [
	{
		value: 920,
		label: i18n.t("activities:activities.modeling")
	},
	{
		value: 921,
		label: i18n.t("activities:activities.gardening")
	},
	{
		value: 922,
		label: i18n.t("activities:activities.cooking")
	},
	{
		value: 923,
		label: i18n.t("activities:activities.diy")
	},
	{
		value: 926,
		label: i18n.t("activities:activities.sewing")
	},
	{
		value: 927,
		label: i18n.t("activities:activities.creativeWorkshop")
	},
	{
		value: 804,
		label: `${i18n.t("activities:activities.other")} ${i18n.t("activities:activities.manualActivity")}`
	}
];

const wellBeingActivities = [
	{
		value: 350,
		label: i18n.t("activities:activities.snoezelen")
	},
	{
		value: 303,
		label: i18n.t("activities:activities.petTherapy")
	},
	{
		value: 300,
		label: i18n.t("activities:activities.hairDresser")
	},
	{
		value: 301,
		label: i18n.t("activities:activities.beautician")
	},
	{
		value: 302,
		label: i18n.t("activities:activities.beauticianManicure")
	},
	{
		value: 935,
		label: i18n.t("activities:activities.wellnessWorkshop")
	},
	{
		value: 305,
		label: i18n.t("activities:activities.tappingMassage")
	},
	{
		value: 806,
		label: `${i18n.t("activities:activities.other")} ${i18n.t("activities:activities.wellbeingActivity")}`
	}
];

export const activityToSelect = [
	{
		label: i18n.t("activities:activities.worship"),
		value: 937
	},
	{
		label: i18n.t("activities:activities.physicalActivity"),
		options: physicalActivities
	},
	{
		label: i18n.t("activities:activities.culturalActivity"),
		options: culturalActivities
	},
	{
		label: i18n.t("activities:activities.socialActivity"),
		options: socialActivities
	},
	{
		label: i18n.t("activities:activities.intellectualActivity"),
		options: intellectualActivity
	},
	{
		label: i18n.t("activities:activities.manualActivity"),
		options: manualActivities
	},
	{
		label: i18n.t("activities:activities.wellbeingActivity"),
		options: wellBeingActivities
	},
	{
		label: i18n.t("activities:activities.otherActivity"),
		value: 807
	}
];
