import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { UserInterface } from "../interfaces/UserInterface";
import ControlPanelLayout from "../layout/ControlPanelLayout";
import AccessControl from "../layout/AccessControl";
import ControlPanelSocket from "../components/ControlPanel/ControlPanelSocket";
import useStoreData from "../hooks/useStoreData";
import * as p from "../constants/Permissions";
import NoData from "../layout/NoData";
import { BlogPostInterface } from "../interfaces/HomeInterface";
import IncoRingtone from "../components/DiaperConnected/NotificationsRingtone/IncoRingtone";

interface Props {
	homeId: number;
	userState: UserInterface;
	userDispatch: React.Dispatch<any>;
	socket: any;
}

const ControlPanel = ({ userState, homeId, userDispatch, socket }: Props): JSX.Element => {
	const { component } = useParams<{ component: string }>();
	const history = useHistory<{ post: BlogPostInterface }>();
	const post = history.location.state ? history.location.state.post : null;
	const { store, dispatch } = useStoreData({ userState, homeId });
	const isBlogger = userState.userConnected.is(p.HOME_BLOG_WRITER);
	const isMessageManager = userState.userConnected.is(p.ALL_FAMILY_USER_MESSAGES);
	const isDiaperManager = userState.userConnected.is(p.DIAPER_APPLICATION);

	return homeId ? (
		<ControlPanelLayout userState={userState} homeId={homeId} userDispatch={userDispatch} dispatch={dispatch}>
			<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.CONTROL_PANEL]}>
				<>
					{component === "incosettings" ? (
						<div className="panel-container">
							<IncoRingtone userPrefs={userState.userConnected.uiprefs} userDispatch={userDispatch} />
						</div>
					) : (
						<>
							<ControlPanelSocket
								isDiaperManager={isDiaperManager}
								isMessageManager={isMessageManager}
								homeId={homeId}
								userState={userState}
								socket={socket}
								display={component}
								seniorsFromHome={store.seniors.seniorsFromHome}
								homeUnits={store.home.homeUnits}
								post={post}
							/>
							{component === "home" && isBlogger ? (
								<Link to="/controlpanel/addpost">
									<button type="button" className="floating-button">
										<FaPlus className="floating-icon" />
									</button>
								</Link>
							) : null}
						</>
					)}
				</>
			</AccessControl>
		</ControlPanelLayout>
	) : (
		<NoData />
	);
};
export default ControlPanel;
