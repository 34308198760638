import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { HealthWorkerFormContextProvider } from "../context/HealthWorkerFormContext";

import PageLayout from "../layout/PageLayout";
import Login from "../pages/Login";

// PAGES to handle healthworkers
import UserData from "../components/UserSettings/UserData";
import HealthWorkers from "../pages/HealthWorkers";
import HealthWorkerTypes from "../pages/HealthWorker_Types";
import HealthWorkerBadges from "../pages/HealthWorker_Badges";
import HealthWorkersDetails from "../pages/HealthWorkers_Details";
import HealthWorkerTimeline from "../pages/HealthWorkerTimeline";
import HealthWorkersAddHealthWorker from "../pages/HealthWorkers_AddHealthWorker";
import HealthWorkersEditHealthWorkers from "../pages/HealthWorkers_EditHealthWorker";
import HealthWorkerBadgeDetails from "../pages/HealthWorker_BadgeDetails";
import HealthWorkerEditBadge from "../pages/HealthWorker_EditBadge";
import HealthWorkerAddBadge from "../pages/HealthWorker_AddBadge";
import HealthWorkersEditPermissions from "../pages/HealthWorkers_EditPermissions";

// PAGES to handle seniors
import Seniors from "../pages/Seniors";
import SeniorsSelectedSenior from "../pages/Seniors_SelectedSenior";
import SeniorsAddSenior from "../pages/Seniors_AddSenior";
import SeniorsFamily from "../pages/Seniors_Family";
import SeniorFamilyDetails from "../pages/SeniorFamilyDetails";
import SeniorsTimeline from "../pages/SeniorsTimeline";

// PAGES TO HANDLE MESSAGES
import MessagesBox from "../pages/MessagesBox";
import MessagesBoxNewMessage from "../pages/MessagesBox_NewMessage";
import MessagesBoxCreateLivemeeting from "../pages/MessageBox_CreateLivemeeting";
import MessagesBoxSelectedSenior from "../pages/MessagesBox_SelectedSenior";

// PAGES TO HANDLE CALENDAR & EVENTS
import CalendarEntries from "../pages/CalendarEntries";
import CalendarEntriesAdd from "../pages/CalendarEntries_Add";
import CalendarEntriesEdit from "../pages/CalendarEntries_Edit";
import CalendarEntriesCalendar from "../pages/CalendarEntries_Calendar";

// PAGES TO HANDLE BLOG
import SocialNews from "../pages/SocialNews";
import SocialNewsBlogPost from "../pages/SocialNews_BlogPost";
import SocialNewsDisplayLikeFamily from "../pages/SocialNews_DisplayLikeFamily";
import SocialNewsEditPost from "../pages/SocialNews_EditPost";
import SocialNewsAddPost from "../pages/SocialNews_AddPost";

// PAGES to handle homes
import AllHomes from "../pages/AllHomes";
import AllHomesAddHome from "../pages/AllHomes_AddHome";
import MyGroupHomes from "../pages/MyGroupHomes";
import MyHomeGeneralView from "../pages/MyHome_GeneralView";
import MyHomeManagerBoard from "../pages/MyHome_ManagerBoard";
import MyHomeGroups from "../pages/MyHome_Groups";
import MyHomeDetails from "../pages/MyHomeDetails";
import MyHomeTypes from "../pages/MyHome_Types";
import MyHomeEditHome from "../pages/MyHome_EditHome";
import MyHomeHomeUnit from "../pages/MyHome_HomeUnit";
import MyHomeEditHomeUnit from "../pages/MyHome_EditHomeUnit";
import MyHomeAddHomeUnit from "../pages/MyHome_AddHomeUnit";
import MyHomeRooms from "../pages/MyHome_Rooms";
import MyHomeRoomDetails from "../pages/MyHome_RoomDetails";
import MyHomeAddRoom from "../pages/MyHome_AddRoom";
import MyHomeEditRoom from "../pages/MyHome_EditRoom";
import MyHomeTimelineSetting from "../pages/MyHome_TimelineSettings";
import MyHomeNotificationRules from "../pages/MyHome_NotificationRules";
import MyHomeAddNotificationRules from "../pages/MyHome_AddNotificationRules";
import MyHomeVisioConstraints from "../pages/MyHome_VisioConstraints";
import MyHomeUpdateVisioConstraints from "../pages/MyHome_UpdateVisioConstraints";
import MyHomeRecipients from "../pages/MyHome_Recipients";
import MyHomeUpdateRecipients from "../pages/MyHome_UpdateRecipients";
import MyHomeActivities from "../pages/MyHome_Activities";
import DevicePreferences from "../pages/DevicePreferences";
import HealthWorkerBudgetRepartition from "../pages/HealthWorkerBudgetRepartition";
import HealthWorkerTypeBudgetCharts from "../pages/HealthWorkerTypeBudgetCharts";
import NotificationsList from "../components/AlertBoard/NotificationsList";
import MyHomeMemo from "../pages/MyHome_Memo";
import MyHomeDocuments from "../pages/MyHome_Documents";

//Moderation
import ModerationFamily from "../pages/Moderation_Family";
import ModerationBlog from "../pages/Moderation_Blog";

// Admin
import Devices from "../pages/Devices";
import DevicesAddDevice from "../pages/Devices_AddDevice";
import AdminHealthWorkers from "../pages/AdminHealthWorkers";
import AdminFamilies from "../pages/AdminFamilies";
import AdminDebugTimeline from "../pages/AdminDebugTimeline";
import AdminTenants from "../pages/AdminTenants";
import AdminAddTenant from "../pages/AdminAddTenant";
import AdminCheckVisio from "../pages/AdminCheckVisio";
import AdminDevicesActions from "../pages/AdminDevicesActions";
import AdminDebugDiapers from "../pages/AdminDebugDiapers";

// DIAPER
import DiapersReferenced from "../pages/DiapersReferenced";
import DiapersReferencedUpdate from "../pages/DiapersReferenced_Update";
import DiaperSensors from "../pages/DiaperSensors";
import DiaperAddSensor from "../pages/DiaperAddSensor";
import DiaperAlertSocket from "../pages/DiaperAlertSocket";
import DiaperSaturationStatistics from "../pages/DiaperSaturationStatistics";
import DiaperChangeStatistics from "../pages/DiaperChangeStatistics";
import DiaperDotationStatistics from "../pages/DiaperDotationStatistics";
import DiaperSettingsRecipients from "../pages/DiaperSettingsRecipients";
import DiaperSettingsSync from "../pages/DiaperSettingsSync";
import DiaperSettingsBluetooth from "../pages/DiaperSettingsBluetooth";

// Control Panel
import ControlPanel from "../pages/ControlPanel";
import LiveMeeting from "../components/ControlPanel/Jitsi.js/LiveMeeting";

const ProtectedApp = (): JSX.Element => {
	const {
		userState,
		userDispatch,
		homeId,
		setHomeId,
		groupId,
		setGroupId,
		tenantId,
		setTenantId,
		homeStore,
		homeDispatch,
		socket,
		display,
		newSeniorDispatch
	} = useContext(UserContext);

	return (
		<Switch>
			<Route exact path="/" component={Login} />
			<Route
				exact
				path="/livemeeting/:subject/:room"
				render={(): JSX.Element => <LiveMeeting userState={userState} homeId={homeId} userDispatch={userDispatch} />}
			/>
			<Route
				exact
				path="/controlpanel/:component"
				render={(): JSX.Element => (
					<ControlPanel userState={userState} homeId={homeId} userDispatch={userDispatch} socket={socket} />
				)}
			/>

			<PageLayout
				userState={userState}
				userDispatch={userDispatch}
				homeId={homeId}
				setHomeId={setHomeId}
				groupId={groupId}
				setGroupId={setGroupId}
				tenantId={tenantId}
				setTenantId={setTenantId}
				homeStore={homeStore}
				homeDispatch={homeDispatch}
				socket={socket}>
				{/* LINKS IN HEADER */}
				<Route exact path="/myaccount" render={(): JSX.Element => <UserData userDispatch={userDispatch} />} />
				<Route
					exact
					path="/notificationsboard"
					render={(): JSX.Element => <NotificationsList userState={userState} homeId={homeId} />}
				/>
				{/* INCO */}
				<Route
					exact
					path="/inco/:component/diaperalert/:sensor"
					render={(): JSX.Element => <DiaperAlertSocket userState={userState} homeId={homeId} socket={socket} />}
				/>
				<Route
					exact
					path="/inco/:component/homediapers"
					render={(): JSX.Element => <DiapersReferenced userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/inco/:component/updatehomediapers"
					render={(): JSX.Element => <DiapersReferencedUpdate userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/inco/:component/diapersensors"
					render={(): JSX.Element => <DiaperSensors userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/inco/:component/diapersensor/:mode"
					render={(): JSX.Element => <DiaperAddSensor userState={userState} homeId={homeId} homeStore={homeStore} />}
				/>
				<Route
					exact
					path="/inco/:component/settings"
					render={(): JSX.Element => (
						<DiaperSettingsRecipients userState={userState} userDispatch={userDispatch} homeId={homeId} />
					)}
				/>
				<Route
					exact
					path="/inco/:component/syncsettings"
					render={(): JSX.Element => <DiaperSettingsSync userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/inco/:component/blesettings"
					render={(): JSX.Element => <DiaperSettingsBluetooth userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/inco/:component/saturationdiapers/:seniorId"
					render={(): JSX.Element => <DiaperSaturationStatistics userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/inco/:component/changediaperstat/:seniorId"
					render={(): JSX.Element => <DiaperChangeStatistics userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/inco/:component/dotationstat"
					render={(): JSX.Element => <DiaperDotationStatistics userState={userState} homeId={homeId} />}
				/>
				{/* DASHBOARD */}
				<Route
					exact
					path="/homeboard"
					render={(): JSX.Element => (
						<MyHomeManagerBoard userState={userState} homeId={homeId} groupId={groupId} tenantId={tenantId} />
					)}
				/>
				{/* HOME SETTINGS */}
				<Route
					exact
					path="/manage/:component/devicepreferences"
					render={(): JSX.Element => <DevicePreferences userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/budgetrepartition"
					render={(): JSX.Element => <HealthWorkerBudgetRepartition userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/hwbudget"
					render={(): JSX.Element => <HealthWorkerTypeBudgetCharts userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/myhomes"
					render={(): JSX.Element => (
						<MyGroupHomes
							setHomeId={setHomeId}
							userState={userState}
							homeStore={homeStore}
							homeDispatch={homeDispatch}
							groupId={groupId}
						/>
					)}
				/>
				<Route
					exact
					path="/manage/mainboard"
					render={(): JSX.Element => <MyHomeGeneralView userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/updatehome"
					render={(): JSX.Element => <MyHomeEditHome userState={userState} homeId={homeId} tenantId={tenantId} />}
				/>
				<Route
					exact
					path="/manage/:component/myHome"
					render={(): JSX.Element => (
						<MyHomeDetails userState={userState} homeId={homeId} groupId={groupId} tenantId={tenantId} />
					)}
				/>
				<Route
					exact
					path="/manage/:component/homeunits"
					render={(): JSX.Element => <MyHomeHomeUnit userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/addhomeunit/:mode"
					render={(): JSX.Element => <MyHomeAddHomeUnit userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/edithomeunit/:id"
					render={(): JSX.Element => <MyHomeEditHomeUnit userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/rooms"
					render={(): JSX.Element => <MyHomeRooms userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/details/:id"
					render={(): JSX.Element => <MyHomeRoomDetails userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/addroom/:mode"
					render={(): JSX.Element => <MyHomeAddRoom userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/editroom/:id"
					render={(): JSX.Element => <MyHomeEditRoom userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/timelinesettings/:seniorId"
					render={(): JSX.Element => <MyHomeTimelineSetting userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/timelinesettings"
					render={(): JSX.Element => <MyHomeTimelineSetting userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/notificationrules"
					render={(): JSX.Element => <MyHomeNotificationRules userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/rule/:mode"
					render={(): JSX.Element => <MyHomeAddNotificationRules userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/documents"
					render={(): JSX.Element => <MyHomeDocuments homeId={homeId} userState={userState} />}
				/>
				<Route
					exact
					path="/manage/:component/list"
					render={(): JSX.Element => <MyHomeRecipients userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/updatelist/:type"
					render={(): JSX.Element => <MyHomeUpdateRecipients userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/visioconfig"
					render={(): JSX.Element => <MyHomeVisioConstraints userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/manage/:component/updatevisioconfig"
					render={(): JSX.Element => <MyHomeUpdateVisioConstraints userState={userState} homeId={homeId} />}
				/>
				{/* ROUTE to handle Moderation */}
				<Route
					exact
					path="/moderation/family"
					render={(): JSX.Element => <ModerationFamily userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/moderation/blog"
					render={(): JSX.Element => <ModerationBlog userState={userState} homeId={homeId} />}
				/>
				{/* ROUTES to handle Memo & Home Timeline */}
				<Route exact path="/tablet/memo" render={(): JSX.Element => <MyHomeMemo userState={userState} homeId={homeId} />} />
				<Route
					exact
					path="/tablet/hometimeline"
					render={(): JSX.Element => <MyHomeActivities userState={userState} homeId={homeId} />}
				/>
				{/* ROUTES to handle HealthWorkers */}
				<Route
					exact
					path="/user/:component/healthworkers"
					render={(): JSX.Element => <HealthWorkers userState={userState} homeId={homeId} />}
				/>
				<HealthWorkerFormContextProvider>
					<Route
						exact
						path="/user/adduser/:step"
						render={(): JSX.Element => (
							<HealthWorkersAddHealthWorker
								userState={userState}
								homeId={homeId}
								groupId={groupId}
								tenantId={tenantId}
								homeStore={homeStore}
							/>
						)}
					/>
				</HealthWorkerFormContextProvider>
				<Route
					exact
					path="/user/healthworker/:id"
					render={(): JSX.Element => <HealthWorkersDetails homeId={homeId} userState={userState} />}
				/>
				<Route
					exact
					path="/user/:component/edithw/:id"
					render={(): JSX.Element => (
						<HealthWorkersEditHealthWorkers userState={userState} homeId={homeId} homeStore={homeStore} />
					)}
				/>
				<Route
					exact
					path="/user/healthworkerbadges"
					render={(): JSX.Element => <HealthWorkerBadges userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/user/hwbagde/:id"
					render={(): JSX.Element => <HealthWorkerBadgeDetails userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/user/hwbagde/edit/:id"
					render={(): JSX.Element => <HealthWorkerEditBadge userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/user/hwbagde/addbadge/:mode"
					render={(): JSX.Element => <HealthWorkerAddBadge userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/user/healthworkeractivities/:id"
					render={(): JSX.Element => <HealthWorkerTimeline userState={userState} />}
				/>
				<Route
					exact
					path="/user/hwpermissions/:id"
					render={(): JSX.Element => <HealthWorkersEditPermissions userState={userState} homeId={homeId} />}
				/>
				{/* ROUTES to handle Seniors and Families */}
				<Route
					exact
					path="/:component/seniors"
					render={(): JSX.Element => <Seniors userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/:component/profile/:seniorId"
					render={(): JSX.Element => (
						<SeniorsSelectedSenior
							userState={userState}
							homeId={homeId}
							newSeniorDispatch={newSeniorDispatch}
							display={display}
						/>
					)}
				/>
				<Route
					exact
					path="/:component/addsenior/:mode"
					render={(): JSX.Element => <SeniorsAddSenior userState={userState} homeId={homeId} display={display} />}
				/>
				<Route
					exact
					path="/senior/senioractivities/:seniorId"
					render={(): JSX.Element => <SeniorsTimeline userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/senior/family"
					render={(): JSX.Element => <SeniorsFamily userState={userState} homeId={homeId} />}
				/>
				<Route exact path="/senior/family/:id" component={SeniorFamilyDetails} />
				{/* ROUTES to handle Mailbox */}
				<Route
					exact
					path="/msg/messagesbox"
					render={(): JSX.Element => <MessagesBox userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/msg/messagesbox/:id/:mode"
					render={(): JSX.Element => <MessagesBox userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/msg/newmessage"
					render={(): JSX.Element => <MessagesBoxNewMessage userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/msg/createlivemeeting"
					render={(): JSX.Element => <MessagesBoxCreateLivemeeting userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/msg/familymessages"
					render={(): JSX.Element => <MessagesBoxSelectedSenior userState={userState} />}
				/>
				{/* ROUTES to handle Calendar */}
				<Route
					exact
					path="/planner/calendar"
					render={(): JSX.Element => <CalendarEntriesCalendar userState={userState} homeId={homeId} />}
				/>

				<Route
					exact
					path="/planner/events"
					render={(): JSX.Element => <CalendarEntries userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/planner/addevent"
					render={(): JSX.Element => <CalendarEntriesAdd userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/planner/editevent/:id"
					render={(): JSX.Element => <CalendarEntriesEdit userState={userState} homeId={homeId} />}
				/>
				{/* ROUTES for Blogger */}
				<Route exact path="/blog/news" render={(): JSX.Element => <SocialNews userState={userState} homeId={homeId} />} />
				<Route
					exact
					path="/blog/news/addpost"
					render={(): JSX.Element => <SocialNewsAddPost userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/blog"
					render={(): JSX.Element => <SocialNewsDisplayLikeFamily userState={userState} homeId={homeId} />}
				/>
				<Route exact path="/blog/post/:postId" render={(): JSX.Element => <SocialNewsBlogPost userState={userState} />} />
				<Route
					exact
					path="/blog/news/editpost/:postId"
					render={(): JSX.Element => <SocialNewsEditPost userState={userState} homeId={homeId} />}
				/>
				{/* ROUTE for Admin And/or Super Admin */}
				<Route
					exact
					path="/support/admindiapers/:component"
					render={(): JSX.Element => <AdminDebugDiapers userState={userState} homeId={homeId} />}
				/>
				<Route exact path="/support/checkvisio" render={(): JSX.Element => <AdminCheckVisio userState={userState} />} />
				<Route
					exact
					path="/support/adminuserdebug"
					render={(): JSX.Element => (
						<AdminHealthWorkers
							userState={userState}
							homeId={homeId}
							setHomeId={setHomeId}
							groupId={groupId}
							homeStore={homeStore}
						/>
					)}
				/>
				<Route
					exact
					path="/support/:component/admintenants"
					render={(): JSX.Element => (
						<AdminTenants userState={userState} tenantId={tenantId} setTenantId={setTenantId} setHomeId={setHomeId} />
					)}
				/>
				<Route
					exact
					path="/support/:component/admintenants/:mode"
					render={(): JSX.Element => <AdminAddTenant userState={userState} />}
				/>
				<Route
					exact
					path="/support/devicesactions"
					render={(): JSX.Element => <AdminDevicesActions userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/support/adminfamilydebug"
					render={(): JSX.Element => <AdminFamilies userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/support/admin"
					render={(): JSX.Element => <AdminDebugTimeline userState={userState} homeId={homeId} />}
				/>
				<Route exact path="/support/types" render={(): JSX.Element => <MyHomeTypes userState={userState} />} />
				<Route
					exact
					path="/support/healthworkertypes"
					render={(): JSX.Element => <HealthWorkerTypes userState={userState} homeId={homeId} />}
				/>
				<Route
					exact
					path="/support/homegroups"
					render={(): JSX.Element => <MyHomeGroups userState={userState} tenantId={tenantId} />}
				/>
				<Route
					exact
					path="/support/:component/allhomes"
					render={(): JSX.Element => (
						<AllHomes
							userState={userState}
							setHomeId={setHomeId}
							groupId={groupId}
							setGroupId={setGroupId}
							homeStore={homeStore}
							homeDispatch={homeDispatch}
						/>
					)}
				/>
				<Route
					exact
					path="/support/:component/addhome/:mode"
					render={(): JSX.Element => (
						<AllHomesAddHome
							userState={userState}
							setHomeId={setHomeId}
							groupId={groupId}
							tenantId={tenantId}
							setTenantId={setTenantId}
							homeStore={homeStore}
							homeDispatch={homeDispatch}
							setGroupId={setGroupId}
						/>
					)}
				/>
				<Route
					exact
					path="/support/devices"
					render={(): JSX.Element => (
						<Devices homeStore={homeStore} userState={userState} homeId={homeId} setHomeId={setHomeId} />
					)}
				/>
				<Route
					exact
					path="/support/device/:mode"
					render={(): JSX.Element => <DevicesAddDevice userState={userState} homeId={homeId} />}
				/>
			</PageLayout>
		</Switch>
	);
};

export default ProtectedApp;
