import React, { createContext, useState, useEffect, useReducer } from "react";
import { Socket } from "phoenix";
import UserReducer from "./reducers/UserReducer";
import HomeDataReducer from "./reducers/HomeDataReducer";
import AdminReducer from "./reducers/AdminReducer";
import ErrorAndSuccessReducer from "./reducers/ErrorAndSuccessReducer";
import User from "./UserClass";
import http from "../services/Interceptor";
import * as url from "../constants/Url";

const combineReducers = slices => (state, action) =>
	Object.keys(slices).reduce(
		(acc, prop) => ({
			...acc,
			[prop]: slices[prop](acc[prop], action)
		}),
		state
	);

const initialUserState = {
	isLogged: JSON.parse(sessionStorage.getItem("isLogged")) || false,
	userConnected:
		new User(
			JSON.parse(localStorage.getItem("firstName")),
			JSON.parse(localStorage.getItem("lastName")),
			JSON.parse(localStorage.getItem("type")),
			JSON.parse(localStorage.getItem("permissions")),
			JSON.parse(localStorage.getItem("tenantId")),
			JSON.parse(localStorage.getItem("groupId")),
			JSON.parse(localStorage.getItem("homeId")),
			JSON.parse(localStorage.getItem("id")),
			JSON.parse(localStorage.getItem("pref")),
			JSON.parse(localStorage.getItem("locale")),
			localStorage.getItem("timezone")
		) || null
};

const esInitialState = {
	error: false,
	success: false,
	msg: null
};

const homeInitialData = {
	home: {
		homeTypes: [],
		myHome: null,
		allHomes: [],
		myGroup: null,
		groups: [],
		tenants: []
	},
	es: esInitialState
};

const adminState = {
	allFamilies: []
	// tenants: []
};

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
	const [userState, userDispatch] = useReducer(UserReducer, initialUserState);
	const [homeStore, homeDispatch] = useReducer(
		combineReducers({
			home: HomeDataReducer,
			es: ErrorAndSuccessReducer
		}),
		homeInitialData
	);

	const [adminStore, adminDispatch] = useReducer(AdminReducer, adminState);
	const [homeId, setHomeId] = useState(null);
	const [groupId, setGroupId] = useState(null);
	const [tenantId, setTenantId] = useState(null);
	const [socket, setSocket] = useState(null);
	const [wsToken, setWsToken] = useState(null);

	useEffect(() => {
		// Get Token To Open WebSocket
		if (userState.isLogged && homeId) {
			http
				.get(`${url.SOCKET}/token`)
				.then(res => setWsToken(res.data.token))
				.catch(() => setWsToken(null));
		}
	}, [userState.isLogged, homeId]);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (wsToken && homeId) {
				let mySocket = new Socket("/socket", { params: { token: wsToken } });
				setSocket(mySocket);
				mySocket.onError(() => console.log("WEBSOCKET > there was an error with the connection!"));
				mySocket.onClose(() => console.log("WEBSOCKET > the connection dropped"));
				mySocket.connect();
			}
		}
		return () => (mounted = false);
	}, [wsToken, homeId]);

	// const login = (data, onSuccess) => {
	// 	http
	// 		.post(`${url.BASEURL}/login`, data)
	// 		.then(res => {
	// 			console.log("LOGIN", res.data.user);
	// 			userDispatch({
	// 				type: "LOGIN",
	// 				payload: res.data.user
	// 			});
	// 			sessionStorage.setItem("isLogged", true);
	// 			localStorage.setItem("firstName", JSON.stringify(res.data.user.first_name));
	// 			localStorage.setItem("lastName", JSON.stringify(res.data.user.last_name));
	// 			localStorage.setItem("type", JSON.stringify(res.data.user.type_id));
	// 			localStorage.setItem("permissions", JSON.stringify(res.data.user.permissions));
	// 			localStorage.setItem("homeId", JSON.stringify(res.data.user.home_id));
	// 			localStorage.setItem("groupId", JSON.stringify(res.data.user.group_id));
	// 			localStorage.setItem("tenantId", JSON.stringify(res.data.user.tenant_id));
	// 			localStorage.setItem("id", JSON.stringify(res.data.user.id));
	// 			localStorage.setItem("pref", JSON.stringify(res.data.uiprefs));
	// 			localStorage.setItem("locale", JSON.stringify(res.data.user.locale));
	// 			localStorage.setItem("timezone", JSON.stringify(res.data.timezone));

	// 			if (res.data.user.locale === "fr") {
	// 				localStorage.setItem("i18next", "fr-FR");
	// 				i18next.changeLanguage("fr-FR");
	// 			} else if (res.data.user.locale === "nl") {
	// 				localStorage.setItem("i18next", "nl-BE");
	// 				i18next.changeLanguage("nl-BE");
	// 			} else {
	// 				localStorage.setItem("i18next", "en-EN");
	// 				i18next.changeLanguage("en-EN");
	// 			}
	// 			onSuccess(res.data.user);
	// 		})
	// 		.catch(() => {
	// 			userDispatch({
	// 				type: "LOGIN_ERROR"
	// 			});
	// 		});
	// };

	useEffect(() => {
		if (userState.isLogged) {
			if (userState.userConnected.home_id) {
				setHomeId(userState.userConnected.home_id);
			} else if (userState.userConnected.group_id) {
				setGroupId(userState.userConnected.group_id);
			} else if (userState.userConnected.tenant_id) {
				setTenantId(userState.userConnected.tenant_id);
			}
		}
		// eslint-disable-next-line
	}, [userState.isLogged]);

	return (
		<UserContext.Provider
			value={{
				userState,
				userDispatch,

				// login,
				homeId,
				setHomeId,
				groupId,
				setGroupId,

				homeStore,
				homeDispatch,

				adminStore,
				adminDispatch,
				tenantId,
				setTenantId,

				socket
			}}>
			{children}
		</UserContext.Provider>
	);
};

export const UserContextConsumer = UserContext.Consumer;
