import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDoubleRight } from "react-icons/fa";
import Select from "react-select";
import OnlyTimePicker from "../../../utils/DatePicker/OnlyTimePicker";
import OnlyDatePicker from "../../../utils/DatePicker/OnlyDatePicker";
import DirtyBedStatistics from "./DirtyBedStatistics";
import ChangeCounters from "./ChangeCounters";
import DisplayPieChart from "./DisplayPieChart";
import Loader from "../../../layout/Loader";
import ChangeTableFiltered from "./ChangeTableFiltered";
import PrintIcon from "../../Buttons/PrintIcon";
import { MdFilterAlt } from "react-icons/md";
import { addYears } from "date-fns";

export default function ChangeStatistics({
	userState,
	changeStatisticStore,
	changeStatisticDispatch,
	seniorsFromHome,
	homeId
}) {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;
	const [resumeChart, setResumeChart] = useState([]);
	const [dirtyBed, setDirtyBed] = useState({ display: true });
	const [diaperSaturated, setDiaperSaturated] = useState({ display: true });
	const [presenceOfRedness, setPresenceOfRedness] = useState({ display: false });
	const [presenceOfStools, setPresenceOfStools] = useState({ display: false });
	const [drawSheetChanged, setDrawSheetChanged] = useState({ display: false });
	const [tornOff, setTornOff] = useState({ display: false });
	const [bringToWc, setBringToWc] = useState({ display: false });

	useEffect(() => {
		if (changeStatisticStore.data) {
			let data = [];
			let sum = 0;

			if (diaperSaturated.display) {
				const percentage_full = changeStatisticStore.data.diaper_saturated.percentages.yes;
				if (percentage_full > 0) {
					const saturationData = {
						name: t("diaper.__diaperSaturated__"),
						value: percentage_full,
						color: "#E74C3C"
					};
					data.push(saturationData);
					sum += percentage_full;
				}
				const percentage_half = changeStatisticStore.data.diaper_saturated.percentages.half;
				if (percentage_half > 0) {
					const saturationData = {
						name: t("diaper.__diaperSaturated__") + " (" + t("diaper.__half__") + ")",
						value: percentage_half,
						color: "#F1C40F"
					};
					data.push(saturationData);
					sum += percentage_half;
				}
			}
			if (dirtyBed.display) {
				const item = changeStatisticStore.data.dirty_bed;
				if (item.percentages.yes > 0) {
					const dirtybedData = {
						name: t("diaper.__dirtyBed__"),
						label: item.counters.yes + "/" + item.counters.total + "(" + item.percentages.yes + ")",
						value: item.percentages.yes,
						color: "#7B241C"
					};
					data.push(dirtybedData);
					sum += item.percentages.yes;
				}
			}
			if (presenceOfStools.display) {
				const percentage = changeStatisticStore.data.presence_of_stools.percentages.yes;
				if (percentage > 0) {
					const stoolsData = {
						name: t("diaper.__presenceOfStools__"),
						value: percentage,
						color: "#9B59B6"
					};
					data.push(stoolsData);
					sum += percentage;
				}
			}
			if (tornOff.display) {
				const percentage = changeStatisticStore.data.torn_off.percentages.yes;
				if (percentage > 0) {
					const tornOffData = {
						name: t("diaper.__diaperTornOff__"),
						value: percentage,
						color: "#5D6D7E"
					};
					data.push(tornOffData);
					sum += percentage;
				}
			}
			if (presenceOfRedness.display) {
				const percentage = changeStatisticStore.data.presence_of_redness.percentages.yes;
				if (percentage > 0) {
					const rednessData = {
						name: t("diaper.__presenceOfRedness__"),
						value: percentage,
						color: "#A93226"
					};
					data.push(rednessData);
					sum += percentage;
				}
			}
			if (drawSheetChanged.display) {
				const percentage = changeStatisticStore.data.draw_sheet_changed.percentages.yes;
				if (percentage > 0) {
					const drawSheetData = {
						name: t("diaper.__drawSheetChanged__"),
						value: percentage,
						color: "#48C9B0"
					};
					data.push(drawSheetData);
					sum += percentage;
				}
			}
			if (bringToWc.display) {
				const percentage = changeStatisticStore.data.bring_to_wc.percentages.yes;
				if (percentage > 0) {
					const bringToWc = {
						name: t("diaper.__bringToWc__"),
						value: percentage,
						color: "#3498DB"
					};
					data.push(bringToWc);
					sum += percentage;
				}
			}

			data.push({
				name: t("diaper.__nothingToReport__"),
				value: Math.round((100 - sum) * 100) / 100,
				color: "#28B463"
			});

			setResumeChart(data);
		}
		// eslint-disable-next-line
	}, [
		changeStatisticStore.data,
		dirtyBed.display,
		tornOff.display,
		presenceOfRedness.display,
		presenceOfStools.display,
		drawSheetChanged.display,
		bringToWc.display,
		diaperSaturated.display
	]);

	useEffect(() => {
		const selectedFilters = [];

		if (diaperSaturated.display) selectedFilters.push("diaper_saturated");
		if (dirtyBed.display) selectedFilters.push("dirty_bed");
		if (tornOff.display) selectedFilters.push("torn_off");
		if (drawSheetChanged.display) selectedFilters.push("draw_sheet_changed");
		if (presenceOfRedness.display) selectedFilters.push("presence_of_redness");
		if (presenceOfStools.display) selectedFilters.push("presence_of_stools");
		if (bringToWc.display) selectedFilters.push("bring_to_wc");

		if (selectedFilters.length > 0) {
			changeStatisticDispatch({ type: "FILTERED_TABLE_DATA", payload: selectedFilters });
		} else {
			changeStatisticDispatch({ type: "RESET_TABLE_DATA", payload: null });
		}
	}, [
		diaperSaturated.display,
		dirtyBed.display,
		tornOff.display,
		drawSheetChanged.display,
		presenceOfRedness.display,
		presenceOfStools.display,
		bringToWc.display,
		changeStatisticStore.startDate,
		changeStatisticStore.endDate,
		changeStatisticStore.endTime,
		changeStatisticStore.startTime
	]);
	const handleSelectAllToggle = () => {
		if (
			diaperSaturated.display &&
			dirtyBed.display &&
			tornOff.display &&
			drawSheetChanged.display &&
			presenceOfRedness.display &&
			presenceOfStools.display &&
			bringToWc.display
		) {
			setDiaperSaturated({ ...diaperSaturated, display: false });
			setDirtyBed({ ...dirtyBed, display: false });
			setTornOff({ ...tornOff, display: false });
			setDrawSheetChanged({ ...drawSheetChanged, display: false });
			setPresenceOfRedness({ ...presenceOfRedness, display: false });
			setPresenceOfStools({ ...presenceOfStools, display: false });
			setBringToWc({ ...bringToWc, display: false });
		} else {
			setDiaperSaturated({ ...diaperSaturated, display: true });
			setDirtyBed({ ...dirtyBed, display: true });
			setTornOff({ ...tornOff, display: true });
			setDrawSheetChanged({ ...drawSheetChanged, display: true });
			setPresenceOfRedness({ ...presenceOfRedness, display: true });
			setPresenceOfStools({ ...presenceOfStools, display: true });
			setBringToWc({ ...bringToWc, display: true });
		}
	};

	function clearChangeFilters() {
		const today = new Date();
		const keysToRemove = ["startDate", "endDate", "startTime", "endTime", "selectedSenior"];
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);
		keysToRemove.forEach(key => localStorage.removeItem(key));
		const currentHourWithMinutes = new Date();
		currentHourWithMinutes.setSeconds(0, 0);

		changeStatisticDispatch({ type: "START_DATE", payload: yesterday });
		changeStatisticDispatch({ type: "END_DATE", payload: today });
		changeStatisticDispatch({ type: "SET_SENIOR", payload: null });
		changeStatisticDispatch({ type: "START_TIME", payload: currentHourWithMinutes });
		changeStatisticDispatch({ type: "END_TIME", payload: currentHourWithMinutes });
	}

	const handleStartDateChange = date => {
		changeStatisticDispatch({ type: "START_DATE", payload: date });
		localStorage.setItem("startDate", date.toISOString());

		const storedEndDate = localStorage.getItem("endDate");
		if (storedEndDate) {
			const existingEndDate = new Date(storedEndDate);

			if (existingEndDate <= date) {
				const newEndDate = new Date(date);
				newEndDate.setDate(newEndDate.getDate() + 1);
				changeStatisticDispatch({ type: "END_DATE", payload: newEndDate });
				localStorage.setItem("endDate", newEndDate.toISOString());
			} else {
				const oneYearAfterStartDate = addYears(date, 1);
				if (existingEndDate > oneYearAfterStartDate) {
					changeStatisticDispatch({ type: "END_DATE", payload: oneYearAfterStartDate });
					localStorage.setItem("endDate", oneYearAfterStartDate.toISOString());
				}
			}
		} else {
			const newEndDate = addYears(date, 1);
			changeStatisticDispatch({ type: "END_DATE", payload: newEndDate });
			localStorage.setItem("endDate", newEndDate.toISOString());
		}
	};

	const handleEndDateChange = date => {
		changeStatisticDispatch({ type: "END_DATE", payload: date });
		localStorage.setItem("endDate", date.toISOString());

		const storedStartDate = localStorage.getItem("startDate");
		if (storedStartDate) {
			const existingStartDate = new Date(storedStartDate);

			if (date < existingStartDate) {
				const adjustedStartDate = new Date(date);
				adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);
				changeStatisticDispatch({ type: "START_DATE", payload: adjustedStartDate });
				localStorage.setItem("startDate", adjustedStartDate.toISOString());
			} else {
				const oneYearBeforeEndDate = addYears(date, -1);
				if (existingStartDate < oneYearBeforeEndDate) {
					changeStatisticDispatch({ type: "START_DATE", payload: oneYearBeforeEndDate });
					localStorage.setItem("startDate", oneYearBeforeEndDate.toISOString());
				}
			}
		} else {
			const newStartDate = addYears(date, -1);
			changeStatisticDispatch({ type: "START_DATE", payload: newStartDate });
			localStorage.setItem("startDate", newStartDate.toISOString());
		}
	};

	const handleStartTimeChange = date => {
		changeStatisticDispatch({ type: "START_TIME", payload: date });
		localStorage.setItem("starTime", date.toISOString());
	};

	const handleEndTimeChange = date => {
		changeStatisticDispatch({ type: "END_TIME", payload: date });
		localStorage.setItem("endTime", date.toISOString());
	};

	return (
		<>
			<div className="big-card dont-print-this mb-2">
				<div className="d-flex justify-content-between align-items-center">
					<h2>{t("diaper.__provisionMonitoring__")}</h2>
					<div>
						<button className="btn btn-sm btn-primary mt-2 dont-print-this" onClick={() => window.print()}>
							{t("common.__print__")} <PrintIcon />
						</button>
						<button type="button" className="btn btn-sm btn-primary mt-2 ml-2 dont-print-this" onClick={clearChangeFilters}>
							{t("common.__resetFilters__")} <MdFilterAlt size={22} />
						</button>
					</div>
				</div>
				<h2>{t("diaper.__changeMonitoring__")}</h2>

				<div className="row mt-4">
					<div className="col-sm-2">
						<p>
							<b>{t("common.__dates__")} : </b>
						</p>
					</div>
					<div className="col-sm-5">
						<OnlyDatePicker
							startDate={changeStatisticStore.startDate}
							dispatch={changeStatisticDispatch}
							type="START"
							userLocale={userLocale}
							onDateChange={handleStartDateChange}
						/>
					</div>
					<div className="col-sm-5">
						<OnlyTimePicker
							startTime={changeStatisticStore.startTime}
							dispatch={changeStatisticDispatch}
							type="START"
							userLocale={userLocale}
							onTimeChange={handleStartTimeChange}
						/>
					</div>
					<div className="col-sm-2">
						<p>{t("common.__toward__")}</p>
					</div>
					<div className="col-sm-5">
						<OnlyDatePicker
							startDate={changeStatisticStore.endDate}
							dispatch={changeStatisticDispatch}
							type="END"
							userLocale={userLocale}
							onDateChange={handleEndDateChange}
						/>
					</div>
					<div className="col-sm-5">
						<OnlyTimePicker
							startTime={changeStatisticStore.endTime}
							dispatch={changeStatisticDispatch}
							type="END"
							userLocale={userLocale}
							onTimeChange={handleEndTimeChange}
						/>
					</div>
					<div className="col-12 mt-2 mb-3">
						<p className="meta">{t("common.__selectInsight__")} :</p>

						<input
							type="checkbox"
							id="selectAll"
							checked={
								presenceOfStools.display &&
								bringToWc.display &&
								diaperSaturated.display &&
								dirtyBed.display &&
								tornOff.display &&
								drawSheetChanged.display &&
								presenceOfRedness.display
							}
							onChange={() => handleSelectAllToggle()}
						/>
						<span className="ml-2 font-weight-bold">
							<label role="button" htmlFor="selectAll">
								{t("common.__selectAll__")}
							</label>
						</span>
					</div>

					<div className="col-sm-4">
						<input
							type="checkbox"
							id="diaperSaturated"
							checked={diaperSaturated.display}
							onChange={() =>
								diaperSaturated.display
									? setDiaperSaturated({ ...diaperSaturated, display: false })
									: setDiaperSaturated({ ...diaperSaturated, display: true })
							}
						/>
						<span className="ml-2 font-weight-bold">
							<label role="button" htmlFor="diaperSaturated">
								{t("diaper.__diaperSaturated__")}
							</label>
						</span>
					</div>
					<div className="col-sm-4">
						<input
							type="checkbox"
							id="dirtyBed"
							checked={dirtyBed.display}
							onChange={() =>
								dirtyBed.display ? setDirtyBed({ ...dirtyBed, display: false }) : setDirtyBed({ ...dirtyBed, display: true })
							}
						/>
						<span className="ml-2 font-weight-bold">
							<label role="button" htmlFor="dirtyBed">
								{t("diaper.__dirtyBed__")}
							</label>
						</span>
					</div>
					<div className="col-sm-4">
						<input
							type="checkbox"
							id="presenceOfStools"
							checked={presenceOfStools.display}
							onChange={() =>
								presenceOfStools.display
									? setPresenceOfStools({ ...presenceOfStools, display: false })
									: setPresenceOfStools({ ...presenceOfStools, display: true })
							}
						/>
						<span className="ml-2 font-weight-bold">
							<label role="button" htmlFor="presenceOfStools">
								{t("diaper.__presenceOfStools__")}
							</label>
						</span>
					</div>
					<div className="col-sm-4">
						<input
							type="checkbox"
							id="tornOff"
							checked={tornOff.display}
							onChange={() =>
								tornOff.display ? setTornOff({ ...tornOff, display: false }) : setTornOff({ ...tornOff, display: true })
							}
						/>
						<span className="ml-2 font-weight-bold">
							<label role="button" htmlFor="tornOff">
								{t("diaper.__diaperTornOff__")}
							</label>
						</span>
					</div>
					<div className="col-sm-4">
						<input
							type="checkbox"
							id="presenceOfRedness"
							checked={presenceOfRedness.display}
							onChange={() =>
								presenceOfRedness.display
									? setPresenceOfRedness({ ...presenceOfRedness, display: false })
									: setPresenceOfRedness({ ...presenceOfRedness, display: true })
							}
						/>
						<span className="ml-2 font-weight-bold">
							<label role="button" htmlFor="presenceOfRedness">
								{t("diaper.__presenceOfRedness__")}
							</label>
						</span>
					</div>
					<div className="col-sm-4">
						<input
							type="checkbox"
							id="drawSheetChanged"
							checked={drawSheetChanged.display}
							onChange={() =>
								drawSheetChanged.display
									? setDrawSheetChanged({ ...drawSheetChanged, display: false })
									: setDrawSheetChanged({ ...drawSheetChanged, display: true })
							}
						/>
						<span className="ml-2 font-weight-bold">
							<label role="button" htmlFor="drawSheetChanged">
								{t("diaper.__drawSheetChanged__")}
							</label>
						</span>
					</div>
					<div className="col-sm-6">
						<input
							type="checkbox"
							id="bringToWc"
							checked={bringToWc.display}
							onChange={() =>
								bringToWc.display
									? setBringToWc({ ...bringToWc, display: false })
									: setBringToWc({ ...bringToWc, display: true })
							}
						/>
						<span className="ml-2 font-weight-bold">
							<label role="button" htmlFor="bringToWc">
								{t("diaper.__bringToWc__")}
							</label>
						</span>
					</div>

					<div className="col-sm-6"></div>
					<div className="col-12 mt-4">
						<p className="meta">{t("common.__filterBySenior__")} :</p>
						<Select
							placeholder={t("senior.__selectSenior__")}
							name="seniors"
							options={seniorsFromHome}
							className="basic-multi-select mb-2 mt-3"
							classNamePrefix="select"
							onChange={e => {
								localStorage.setItem("selectedSenior", JSON.stringify(e));
								changeStatisticDispatch({ type: "SET_SENIOR", payload: e });
							}}
							isClearable
							value={changeStatisticStore.selectedSenior ? changeStatisticStore.selectedSenior : 0}
						/>
					</div>
				</div>
			</div>

			{changeStatisticStore.loading ? (
				<Loader />
			) : (
				<>
					{changeStatisticStore.data ? (
						<>
							<ChangeCounters statistics={changeStatisticStore.data} />
							<div className="big-card mb-3">
								<div className="row">
									<DisplayPieChart
										display={true}
										data={resumeChart}
										title={t("diaper.__postChangeStats__")}
										height={600}
										innerRadius={180}
										outerRadius={230}
										paddingAngle={5}
									/>
								</div>
							</div>
							<DirtyBedStatistics
								homeId={homeId}
								userState={userState}
								selectedSenior={changeStatisticStore.selectedSenior}
								startDate={changeStatisticStore.startDate}
								endDate={changeStatisticStore.endDate}
								display={dirtyBed.display}
							/>
							<div className="big-card mt-3 mb-3 dont-print-this">
								<p>{t("common.__detailedView__")}</p>
								<ChangeTableFiltered
									changeStatisticStore={changeStatisticStore}
									dispatch={changeStatisticDispatch}
									data={changeStatisticStore.filteredTableData}
									seniorsFromHome={seniorsFromHome}
								/>
							</div>
						</>
					) : (
						<div className="mt-3">
							<div className="big-card">
								<p className="meta">{t("common.__noElement__")}</p>
								<p className="red">
									<b>
										<FaAngleDoubleRight /> {t("common.__selectNewDate__")}
									</b>
								</p>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
}
