import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";
import { useForm, FormProvider } from "react-hook-form";
import MyInputNumber from "../../../utils/Form/MyInputNumber";
import MyInputText from "../../../utils/Form/MyInputText";
import { ShowState } from "../../../utils/ModalContainer";

interface Props {
	show: ShowState;
	setShow: React.Dispatch<React.SetStateAction<ShowState>>;
	diaperDispatch: React.Dispatch<any>;
}

interface InputProps {
	address: string;
	temperature: number;
	humidity: number;
}

export default function EditSensor({ show, setShow, diaperDispatch }: Props): JSX.Element {
	const { t } = useTranslation("common");
	const sensor = show.info.sensor;
	const methods = useForm<InputProps>({ mode: "onBlur" });
	const { handleSubmit } = methods;
	const [error, setError] = useState(false);

	const onSubmit = (values: InputProps): void => {
		setError(false);
		const data = {
			diaper_sensor: {
				mac_address: values.address,
				delta_t: values.temperature,
				delta_h: values.humidity
			}
		};
		http
			.put(`${url.BASEURL}/rht/diaper_sensors/${sensor.id}`, data)
			.then(res => {
				const sensorIsAffected = sensor.senior ? true : false;
				diaperDispatch({
					type: "EDIT_SENSOR",
					payload: sensorIsAffected ? { ...res.data.diaper_sensor, senior: sensor.senior } : res.data.diaper_sensor
				});
				setShow({ show: false, info: { sensor: null }, success: true });
			})
			.catch(() => setError(true));
	};

	return (
		<FormProvider {...methods}>
			<h2>{t("diaper.__sensor__")}</h2>
			<p className="blue">
				<b>{t("common.__update__")} :</b>
			</p>
			<HandleError
				error={error}
				setError={setError}
				message={t("common.__editError__") + " " + t("diaper.__sensorEditCreateError__")}
			/>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-md-12 mb-3">
						<MyInputText
							label={`MAC address`}
							name="address"
							behavior={{ required: true, maxLength: 17 }}
							value={sensor.mac_address}
						/>
					</div>
					<div className="col-md-6">
						<MyInputNumber
							label={t("diaper.__humidityCalibration__")}
							name="humidity"
							type="number"
							behavior={{ required: true, max: 100, min: -100 }}
							placeholder="0"
							step="1"
							allowReset
							value={sensor.delta_h}
						/>
					</div>
					<div className="col-md-6">
						<MyInputNumber
							label={t("diaper.__temperatureCalibration__")}
							name="temperature"
							type="number"
							behavior={{ required: true, max: 100, min: -100 }}
							placeholder="0"
							step="0.1"
							allowReset
							value={sensor.delta_t}
						/>
					</div>
				</div>
				<button type="submit" className="btn btn-sm btn-primary mt-4">
					{t("common.__save__")}
				</button>
			</form>
		</FormProvider>
	);
}
