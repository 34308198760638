import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaRegEdit, FaAngleRight } from "react-icons/fa";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { fromToUrl } from "@e4ia/e4link_modules.datetime";
import { UserInterface } from "../../interfaces/UserInterface";
import { EventInterface, EventStoreInterface } from "../../interfaces/HomeInterface";
import ModalEvent from "./ModalEvent";
import RangePicker from "../../utils/DatePicker/RangePicker";
import HandleSuccess from "../../layout/HandleSuccess";
import HandleError from "../../layout/HandleError";
import RemoveButtons from "./RemoveButtons";
import Loader from "../../layout/Loader";
import ModalContainer, { ShowState } from "../../utils/ModalContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

interface Props {
	homeId: number;
	userState: UserInterface;
	eventsState: EventStoreInterface;
	eventsDispatch: React.Dispatch<any>;
}

const EventsList = ({ homeId, userState, eventsState, eventsDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [show, setShow] = useState<ShowState>({ show: false, info: null, title: "" });
	const [update, setUpdate] = useState(false);
	const events = eventsState.events;
	const startDate = eventsState.startDate;
	const endDate = eventsState.endDate;

	useEffect(() => {
		if (homeId) {
			eventsDispatch({
				type: "LOADING_EVENTS"
			});
			const fromTo = fromToUrl(startDate, endDate);
			http
				.get(`${url.HOMESURL}/${homeId}/home_calendar_entries/${fromTo}`)
				.then(res => {
					const allEvents = res.data.calendar_entries;
					const eventsWithoutVisio = allEvents.filter((e: EventInterface) => e.entry_type_id !== 11);
					const sortedEventsList = eventsWithoutVisio
						.slice()
						.sort((a: EventInterface, b: EventInterface) => a.begin_datetime.localeCompare(b.begin_datetime));
					eventsDispatch({
						type: "GET_EVENTS",
						payload: { data: sortedEventsList, locale: userState.userConnected.locale }
					});
				})
				.catch(() => {
					eventsDispatch({
						type: "ERROR_EVENTS",
						payload: t("common.__noData__")
					});
				});
		}
		// eslint-disable-next-line
	}, [homeId, startDate, endDate, update]);

	const columns = [
		{
			Header: t("common.__type__"),
			accessor: "type",
			Filter: SelectColumnFilter,
			minWidth: 40,
			width: 45,
			maxWidth: 50,
			Cell: (row: any) => (
				<span className={row.row.original.entry_type_id === 1 ? "colored-tag blue" : "colored-tag red"}>
					{row.row.values.type}
				</span>
			)
		},
		{
			Header: t("common.__name__"),
			accessor: "info",
			Cell: (row: any) => (
				<p>
					{row.row.original.recurring_entry_id && (
						<span className="small-meta">
							{t("calendar.__recurringEvent__")}
							<br />
						</span>
					)}
					<span
						className="fake-link blue"
						onClick={(): void => setShow({ show: true, info: row.row.original, title: t("calendar.__workshop__") })}>
						<b>{row.row.values.info}</b>
					</span>
					{row.row.original.detail ? (
						<>
							<br /> {row.row.original.detail}
						</>
					) : null}
				</p>
			)
		},
		{
			Header: t("common.__date__"),
			accessor: "timing"
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			disableSortBy: true,
			minWidth: 50,
			width: 50,
			maxWidth: 50,
			Cell: (row: any) => (
				<div className="center">
					<Link to={`/planner/editevent/${row.row.values.id}`}>
						<FaRegEdit className="table-icon mr-2" role="button" />
					</Link>
					<RemoveButtons
						eventId={row.row.values.id}
						eventDate={row.row.original.begin_datetime}
						eventsDispatch={eventsDispatch}
						recurringEvent={row.row.original.recurring_entry_id}
						setUpdate={setUpdate}
					/>
				</div>
			)
		}
	];

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<div className="form-inline">
					<label className="mr-1">
						<FaAngleRight className="mr-1" />
						{t("common.__selectedDates__")} :
					</label>
					<RangePicker
						userLocale={userState.userConnected.locale}
						startDate={startDate}
						endDate={endDate}
						dispatch={eventsDispatch}
					/>
				</div>
				<Link to="/planner/addevent">
					<button type="button" className="btn btn-sm btn-primary">
						{t("calendar.__addAnimation__")}
					</button>
				</Link>
			</div>

			<HandleError error={eventsState.error} dispatch={eventsDispatch} message={eventsState.msg} />
			<HandleSuccess success={eventsState.success} dispatch={eventsDispatch} message={eventsState.msg} />

			{eventsState.loading ? (
				<Loader />
			) : events.length > 0 ? (
				<Table columns={columns} data={events} />
			) : (
				<div className="header-details mt-4" role="alert">
					<p>{t("common.__noElement__")}</p>
				</div>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<ModalEvent show={show} userState={userState} />
			</ModalContainer>
		</>
	);
};

export default EventsList;
