import React from "react";
import { useHistory, Link } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import DisplayName from "../../../utils/DisplayName";
import HeaderContainer from "../../../layout/HeaderContainer";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";
import { useTranslation } from "react-i18next";
import HandleError from "../../../layout/HandleError";
import HandleSuccess from "../../../layout/HandleSuccess";
import NoData from "../../../layout/NoData";

const AllHomesList = ({ homeStore, homeDispatch, setGroupId, setHomeId, homes }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const groups = homeStore.home.groups;
	// const homeTypes = homeStore.home.homeTypes;
	const handleGroupName = groupId => {
		if (groupId === null) {
			return t("home.__singleHome__");
		} else {
			return `${DisplayName(groupId, groups)}`;
		}
	};

	const data = homes.map(function (home) {
		return {
			...home,
			// type: `${DisplayName(home.type_id, homeTypes)}`,
			groupName: `${handleGroupName(home.group_id)}`,
			devices: home.devices.length,
			seniors: home.seniors.length,
			heatlworkers: home.users.length
		};
	});

	const removeHome = id => {
		http
			.delete(`${constant.BASEURL}/homes/${id}`)
			.then(() => {
				homeDispatch({
					type: "REMOVE_HOME",
					payload: id
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__removeHomeSuccess__")
				});
			})
			.catch(() => {
				homeDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__delError__")
				});
			});
	};

	const handleHome = id => {
		setHomeId(id);
		history.push(`/manage/me/myhome`);
	};

	const handleHomeForSeniorsAndHealthW = id => {
		setHomeId(id);
	};

	const handleGroup = id => {
		setGroupId(id);
		history.push(`/manage/organisation/myhomes`);
	};

	const columns = [
		{
			Header: t("home.__group__"),
			accessor: "groupName",
			Filter: SelectColumnFilter,
			Cell: row =>
				row.row.values.groupName === t("home.__singleHome__") ? null : (
					<span
						className="category-outline-badge fake-link blue"
						role="button"
						tabIndex="0"
						onClick={() => handleGroup(row.row.original.group_id)}>
						{row.row.values.groupName}
					</span>
				)
		},
		{
			Header: t("common.__home__"),
			accessor: "name",
			Cell: row => (
				<b className=" fake-link blue" role="button" tabIndex="0" onClick={() => handleHome(row.row.original.id)}>
					{row.row.values.name}
				</b>
			)
		},
		// {
		// 	Header: t("common.__type__"),
		// 	accessor: "type",
		// 	Filter: SelectColumnFilter
		// },
		{
			Header: t("common.__device__"),
			accessor: "devices",
			Cell: ({ cell: { value }, row }) => (
				<div className="center" role="button" onClick={() => handleHomeForSeniorsAndHealthW(row.original.id)}>
					<Link to="/support/devices">{value}</Link>
				</div>
			)
		},
		{
			Header: t("common.__senior__"),
			accessor: "seniors",
			Cell: ({ cell: { value }, row }) => (
				<div className="center" role="button" onClick={() => handleHomeForSeniorsAndHealthW(row.original.id)}>
					<Link to="/senior/seniors">{value}</Link>
				</div>
			)
		},
		{
			Header: t("homeBoard.__team__"),
			accessor: "heatlworkers",
			Cell: ({ cell: { value }, row }) => (
				<div className="center" role="button" onClick={() => handleHomeForSeniorsAndHealthW(row.original.id)}>
					<Link to="/user/hw/healthworkers">{value}</Link>
				</div>
			)
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			disableSortBy: true,
			width: 24,
			Cell: ({ cell: { value } }) => (
				<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeHome(value)} />
			)
		}
	];

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("home.__homes__")}
				addButton={t("home.__addHome__")}
				link={"/support/organisation/addhome/add"}
			/>
			<HandleError error={homeStore.es.error} dispatch={homeDispatch} message={homeStore.es.msg} />
			<HandleSuccess success={homeStore.es.success} dispatch={homeDispatch} message={homeStore.es.msg} />
			{data.length > 0 ? <Table columns={columns} data={data} /> : <NoData />}
		</div>
	);
};

export default AllHomesList;
