import React from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";

export default function PieChartContainer({
	data,
	dataKey,
	height,
	innerRadius = 45,
	outerRadius = 85,
	paddingAngle = 1
}) {
	const COLORS = ["#50c878", "#fa5456", "#00a0e3", "#ffae42"];

	return (
		<ResponsiveContainer width={"100%"} height={height}>
			<PieChart>
				<Legend verticalAlign="top" />
				<Pie
					data={data}
					dataKey={dataKey}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					paddingAngle={paddingAngle}
					label>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={entry.color || COLORS[index % COLORS.length]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
}
