import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { FamilyInterface, NewSeniorInterface } from "../../../interfaces/UserInterface";

interface Props {
	newFamilyStore: NewSeniorInterface;
	newFamilyDispatch: React.Dispatch<any>;
}

const SearchFamilyUser = ({ newFamilyStore, newFamilyDispatch }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");
	const [userFound, setUserFound] = useState<null | string | FamilyInterface>(null);
	const inputRef = useRef<any>();

	const searchUser = (): void => {
		if (inputRef.current && inputRef.current.value && inputRef.current.value.match(/^\S+@\S+\.\S+$/)) {
			http
				.get(`${url.BASEURL}/search/family_users?email=${inputRef.current.value}`)
				.then(res => setUserFound(res.data.family_user))
				.catch(err => {
					if (err.response.status === 403) {
						setUserFound("AFFILIATE_FOUND");
					} else {
						setUserFound("NO_ACCOUNT");
					}
				});
		} else {
			setUserFound("ERROR");
		}
	};

	const selectThisAccount = (family: FamilyInterface): void => {
		newFamilyDispatch({
			type: "SET_EXISTING_FAMILY",
			payload: family
		});
		setUserFound(null);
	};

	return newFamilyStore.displaySearchFamilyUser ? (
		<>
			<div className="header-details">
				<p className="mt-2">{t("family.__searchFamilyAccount__")}</p>
			</div>

			<p className="mb-3 mt-4">
				<FaAngleRight /> <b>{t("family.__searchFamilyUser__")}</b>
			</p>

			<div className="d-flex justify-content-between mb-3 mt-3">
				<div className="input-group mb-3">
					<input
						type="text"
						className="form-control"
						placeholder={t("common.__mail__")}
						aria-label="Family's mail"
						ref={inputRef}
					/>
					<div className="input-group-append" onClick={searchUser}>
						<button className="btn btn-outline-secondary" type="button">
							{t("common.__search__")}
						</button>
					</div>
				</div>
			</div>

			{userFound && typeof userFound !== "string" && userFound.id ? (
				<>
					<p className="mb-3 mt-4 blue">
						<FaAngleRight /> <b>{t("family.__userFound__")} :</b>
					</p>
					<div className="input-group mb-3">
						<input
							type="text"
							className="form-control"
							value={`${userFound.last_name.toUpperCase()} ${userFound.first_name}`}
							aria-label="Family's username"
							disabled
						/>
						<div className="input-group-append" onClick={(): void => selectThisAccount(userFound)}>
							<button className="btn btn-primary" type="button">
								{t("common.__validate__")}
							</button>
						</div>
					</div>
				</>
			) : (
				<>
					{userFound ? (
						userFound === "ERROR" ? (
							<p className="error-msg mt-4">
								<b>{t("users.__forgotPasswordError__")}</b>
							</p>
						) : userFound === "AFFILIATE_FOUND" ? (
							<p className="error-msg mt-4">
								<b>{t("family.__isAffiliateUser__")}</b>
							</p>
						) : userFound === "NO_ACCOUNT" ? (
							<p className="red mb-2 mt-4">
								<b>{t("family.__noUserFound__")}</b>
							</p>
						) : null
					) : null}
				</>
			)}
		</>
	) : null;
};
export default SearchFamilyUser;
