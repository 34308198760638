import i18n from "../i18n/i18next";

export const languages = [
	{
		id: 1,
		name: i18n.t("common:users.__french__"),
		locale: "fr"
	},
	{
		id: 2,
		name: i18n.t("common:users.__english__"),
		locale: "en"
	},
	{
		id: 3,
		name: i18n.t("common:users.__dutch__"),
		locale: "nl"
	},
	{
		id: 4,
		name: i18n.t("common:users.__german__"),
		locale: "de"
	}
];
