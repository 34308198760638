interface CountsInterface {
	nb_extra: number;
	nb_found_but_bad: number;
	nb_not_found: number;
	nb_ok: number;
	nb_total: number;
}

export interface DeltaProps {
	chart: { name: string; value: number }[];
	result: { counts: CountsInterface };
	seniors_results: {
		counts: CountsInterface;
		senior: { first_name: string; last_name: string; id: number };
		values: {
			date: string;
			index: number;
			type: string;
		}[];
	}[];
}

export interface RatioProps {
	nb_form: number;
	nb_total: number;
	percentage: number;
}

export interface AverageProps {
	CIJ:number;
	average: number;
	nb: number;
	nb_without_price: number;
	sum: number | null;
}

interface InitialStateProps {
	loading: boolean;
	ratio: RatioProps | null;
	average: AverageProps | null;
	delta: DeltaProps | null;
	filteredDelta: any | null;
	filter: string;
}

export const DotationReducer = (state: InitialStateProps, action: { type: string; payload?: any }): any => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true
			};
		case "LOADED":
			return {
				...state,
				loading: false
			};
		case "SET_RATIO":
			return {
				...state,
				ratio: action.payload
			};
		case "SET_AVERAGE":
			return {
				...state,
				average: action.payload
			};
		case "SET_DELTA":
			return {
				...state,
				delta: action.payload
			};
		case "FILTER_DELTA": {
			const initialResult = state.delta?.seniors_results ? state.delta.seniors_results : null;
			const initialResultConcatened = initialResult
				? initialResult.map((result: any) => {
						return result.values.map((value: { date: string; type: string; change?: any; index?: number }) => {
							return {
								date: value.date,
								type: value.type,
								change: value.change ? value.change : null,
								seniorName: `${result.senior.last_name} ${result.senior.first_name}`,
								seniorId: result.senior.id,
								dotation:
									value.type !== "EXTRA" && value.index ? result.senior.provisions[value.index] : result.senior.provisions[0]
							};
						});
				  })
				: [];
			const initialResultFlat = initialResultConcatened.flat();
			return {
				...state,
				filteredDelta: initialResultFlat.filter((value: any) => value.type === action.payload),
				filter: action.payload
			};
		}
		case "DISPLAY_ALL":
			return {
				...state,
				filteredDelta: [],
				filter: action.payload
			};
		default:
			return state;
	}
};
