import User from "../UserClass";

const UserReducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":
			return {
				...state,
				isLogged: true,
				userConnected: new User(
					action.payload.first_name,
					action.payload.last_name,
					action.payload.type_id,
					action.payload.permissions,
					action.payload.tenant_id,
					action.payload.group_id,
					action.payload.home_id,
					action.payload.id,
					action.payload.uiprefs,
					action.payload.locale,
					action.payload.timezone
				)
			};

		case "UPDATE_MY_ACCOUNT": {
			const userPreferences = action.payload.uiprefs ? action.payload.uiprefs : null;
			localStorage.setItem("pref", JSON.stringify(userPreferences));
			localStorage.setItem("firstName", JSON.stringify(action.payload.first_name));
			localStorage.setItem("lastName", JSON.stringify(action.payload.last_name));
			localStorage.setItem("locale", JSON.stringify(action.payload.locale));
			localStorage.setItem("timezone", JSON.stringify(action.payload.timezone));
			return {
				...state,
				isLogged: true,
				userConnected: new User(
					action.payload.first_name,
					action.payload.last_name,
					action.payload.type_id,
					action.payload.permissions,
					action.payload.tenant_id,
					action.payload.group_id,
					action.payload.home_id,
					action.payload.id,
					action.payload.uiprefs,
					action.payload.locale,
					action.payload.timezone
				)
			};
		}

		case "LOGOUT":
			return {
				...state,
				isLogged: false
			};

		default:
			return state;
	}
};

export default UserReducer;
