import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import { UserContext } from "../../context/UserContext";
import ModalContainer from "../../utils/ModalContainer";
import UpdateFamilyUser from "../Seniors/Family/UpdateFamilyUser";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import FamilyListForAdmin from "./FamilyListForAdmin";
import { FamilyInterface } from "../../interfaces/UserInterface";

export interface IExtendedFamily extends FamilyInterface {
	homeId: number[];
}

interface Props {
	adminStore: any;
	adminDispatch: React.Dispatch<any>;
	update: boolean;
	setUpdate: () => void;
	editFamily: {
		show: boolean;
		info: any;
		title?: string;
		success?: boolean;
	};
	setEditFamily: React.Dispatch<React.SetStateAction<any>>;
	homeId: number | null;
	isSuperAdmin: boolean;
}

const FamilyListForAdminContainer = ({
	adminStore,
	adminDispatch,
	update,
	setUpdate,
	editFamily,
	setEditFamily,
	homeId,
	isSuperAdmin
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { homeStore, homeDispatch } = useContext(UserContext);
	const families = adminStore.allFamilies;
	const [familyList, setFamilyList] = useState(families);
	const [affiliates, setAffiliates] = useState(families.filter((family: FamilyInterface) => !family.principal));
	const [principalCounter, setPrincipalCounter] = useState(0);

	const filterFamilyList = (filter: string): void => {
		if (homeId) {
			if (filter === "affiliate") {
				const familiesByHome = families.filter((family: IExtendedFamily) => family.homeId.includes(homeId));
				setFamilyList(familiesByHome.filter((family: IExtendedFamily) => !family.principal));
			} else {
				const familiesByHome = families.filter((family: IExtendedFamily) => family.homeId.includes(homeId));
				setFamilyList(familiesByHome.filter((family: IExtendedFamily) => family.principal));
			}
		} else {
			if (filter === "affiliate") {
				setFamilyList(families.filter((family: IExtendedFamily) => !family.principal));
			} else {
				setFamilyList(families.filter((family: IExtendedFamily) => family.principal));
			}
		}
	};

	useEffect(() => {
		if (homeId) {
			const familiesByHome = families.filter((family: IExtendedFamily) => family.homeId.includes(homeId));
			setFamilyList(familiesByHome);
			setAffiliates(familiesByHome.filter((family: IExtendedFamily) => !family.principal));
			const principals = familiesByHome.filter((family: IExtendedFamily) => family.principal);
			setPrincipalCounter(principals.length);
		} else {
			setFamilyList(families);
			setAffiliates(families.filter((family: IExtendedFamily) => !family.principal));
			setPrincipalCounter(families.length);
		}
	}, [families, homeId, update]);

	const unlock = (id: number): void => {
		if (id) {
			http.put(`${url.BASEURL}/family_users/${id}/unlock`).then(() => {
				setUpdate();
			});
		}
	};

	const removeFamilyAccount = (id: number): void => {
		homeDispatch({
			type: "RESET_ES"
		});
		http
			.delete(`${url.BASEURL}/family_users/${id}`)
			.then(() => {
				adminDispatch({
					type: "DELETE_FAMILY",
					payload: id
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("healthworker.__removeSuccess__")
				});
				setUpdate();
			})
			.catch(() => {
				homeDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__delError__")
				});
			});
	};

	const resetRgpd = (familyId: number): void => {
		homeDispatch({
			type: "RESET_ES"
		});
		http
			.put(`${url.BASEURL}/family_users/${familyId}/reset/rgpd`)
			.then(() => {
				setUpdate();
			})
			.catch(() => {
				homeDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__editError__")
				});
			});
	};

	return (
		<div className="big-card">
			{adminStore.allFamilies.length === 0 ? (
				<p className="meta mt-4">
					<FaAngleRight className="mr-2" />
					{t("common.__noElement__")}
				</p>
			) : (
				<FamilyListForAdmin
					familyList={familyList}
					affiliates={affiliates}
					principalCounter={principalCounter}
					filterFamilyList={filterFamilyList}
					unlock={unlock}
					removeFamilyAccount={removeFamilyAccount}
					resetRgpd={resetRgpd}
					setEditFamily={setEditFamily}
					isSuperAdmin={isSuperAdmin}
					homeStore={homeStore}
					homeDispatch={homeDispatch}
				/>
			)}
			<ModalContainer show={editFamily} setShow={setEditFamily}>
				<UpdateFamilyUser show={editFamily} setShow={setEditFamily} />
			</ModalContainer>
		</div>
	);
};

export default FamilyListForAdminContainer;
