import { NewSeniorInterface } from "../../interfaces/UserInterface";

export const newSeniorState = {
	displaySearchFamilyUser: false,
	displayAddFamilyUser: true,
	existingFamilyUserIds: [],
	familyUsersSelected: [],
	newFamilyUsers: [],
	senior: null,
	rooms: [],
	room_id: null,
	loading: false,
	error: { showError: false, errorMsg: null },
	step: "SENIOR"
};

export const NewSeniorReducer = (state: NewSeniorInterface, action: { type: string; payload?: any }): any => {
	switch (action.type) {
		case "DISPLAY_SEARCH_BOX":
			return {
				...state,
				displaySearchFamilyUser: true,
				displayAddFamilyUser: false
			};
		case "HIDE_SEARCH_BOX":
			return {
				...state,
				displaySearchFamilyUser: false
			};
		case "DISPLAY_ADD_BOX":
			return {
				...state,
				displayAddFamilyUser: true,
				displaySearchFamilyUser: false
			};
		case "HIDE_ADD_BOX":
			return {
				...state,
				displayAddFamilyUser: false
			};

		case "SAVE_NEW_SENIOR":
			return {
				...state,
				senior: action.payload.senior,
				room_id: action.payload.room_id,
				step: "FAMILY"
			};
		case "GO_TO_RESUME":
			return {
				...state,
				step: "RESUME"
			};
		case "GO_TO_FAMILY":
			return {
				...state,
				step: "FAMILY"
			};
			case "GO_TO_SENIOR":
			return {
				...state,
				step: "SENIOR"
			};
		case "SET_EXISTING_FAMILY":
			return {
				...state,
				existingFamilyUserIds:
					state.existingFamilyUserIds.length > 0 && state.existingFamilyUserIds.includes(action.payload.id)
						? state.existingFamilyUserIds
						: [...state.existingFamilyUserIds, action.payload.id],
				familyUsersSelected:
					state.existingFamilyUserIds.length > 0 && state.existingFamilyUserIds.includes(action.payload.id)
						? state.familyUsersSelected
						: [...state.familyUsersSelected, action.payload],
				displaySearchFamilyUser: false
			};
		case "SET_NEW_FAMILY": {
			const newFamilyUsersMail = state.newFamilyUsers.length > 0 ? state.newFamilyUsers.map(elt => elt.email) : null;
			return {
				...state,
				newFamilyUsers:
					newFamilyUsersMail && newFamilyUsersMail.includes(action.payload.email)
						? state.newFamilyUsers
						: [...state.newFamilyUsers, action.payload],
				displayAddFamilyUser: false
			};
		}
		case "DEL_SELECTED_FAMILY": {
			const isAnExistingFamily = action.payload.id;
			return {
				...state,
				existingFamilyUserIds: isAnExistingFamily
					? state.existingFamilyUserIds.filter(elt => elt !== action.payload.id)
					: state.existingFamilyUserIds,
				familyUsersSelected: isAnExistingFamily
					? state.familyUsersSelected.filter(elt => elt.id !== action.payload.id)
					: state.familyUsersSelected,
				newFamilyUsers: isAnExistingFamily
					? state.newFamilyUsers
					: state.newFamilyUsers.filter(elt => elt.email !== action.payload.email)
			};
		}
		case "LOADING":
			return {
				...state,
				loading: true
			};
		case "SET_ROOMS_LIST":
			return {
				...state,
				rooms: action.payload
			};
		case "ERROR":
			return {
				...state,
				error: { showError: true, errorMsg: action.payload },
				loading: false
			};
		case "RESET_ES":
			return {
				...state,
				error: { showError: false, errorMsg: null }
			};
		default:
			return state;
	}
};
