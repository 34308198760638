import i18n from "../i18n/i18next";

export const badgeValidityType = (id: number): string | null => {
	switch (id) {
		case 0:
			return i18n.t("common:badge.__noRestriction__");
		case 1:
			return i18n.t("common:badge.__occasionalBadge__");
		case 2:
			return i18n.t("common:badge.__restrictedTime__");
		case 3:
			return i18n.t("common:badge.__restrictedDays__");
		default:
			return null;
	}
};
