import React, { useEffect, useContext } from "react";
import { HomeContext } from "../context/HomeContext";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import AddDevice from "../components/Devices/AddDevice";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";
import SelectHomeInput from "../layout/SelectHomeInput";
import Loader from "../layout/Loader";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const Devices_AddDevice = ({ userState, homeId }: Props): JSX.Element => {
	const { devicesStore, devicesDispatch } = useContext(HomeContext);

	useEffect(() => {
		http.get(`${url.BASEURL}/device_serial_number_types`).then(res => {
			devicesDispatch({
				type: "GET_DEVICE_TYPES",
				payload: res.data.device_serial_number_types
			});
		});
	}, []);

	useEffect(() => {
		return function cleanup(): void {
			devicesDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.SUPER_ADMIN, p.ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={
					homeId ? (
						devicesStore.devices.types.length > 0 ? (
							<AddDevice
								homeId={homeId}
								deviceError={devicesStore.es}
								deviceType={devicesStore.devices.types}
								devicesDispatch={devicesDispatch}
							/>
						) : (
							<Loader />
						)
					) : (
						<SelectHomeInput />
					)
				}
			/>
		</AccessControl>
	);
};
export default Devices_AddDevice;
