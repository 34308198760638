import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaVolumeUp } from "react-icons/fa";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

interface Props {
	userPrefs: { inco_ringtone: number } | undefined;
	userDispatch: React.Dispatch<any>;
}

const IncoRingtone = ({ userPrefs, userDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	const selectedRingtone = userPrefs && userPrefs.inco_ringtone ? userPrefs.inco_ringtone : null;

	const displayRing = (selectedRing: number): void => {
		const audio = new Audio(`${process.env.PUBLIC_URL}/audio/inco_notification_${selectedRing}.mp3`);
		audio.play();
	};

	const updateUiprefs = (pref: number): void => {
		const userData = {
			user: {
				uiprefs: {
					inco_ringtone: pref
				}
			}
		};
		http.put(`${url.BASEURL}/me`, userData).then(res => {
			userDispatch({
				type: "UPDATE_MY_ACCOUNT",
				payload: res.data.user
			});
		});
	};

	// 4 rings to select in public folder > audio
	const rings = [1, 2, 3, 4];

	return (
		<div className="big-card">
			<h2>{t("diaper.__ringTone__")}</h2>
			<p className="mt-4 mb-4">{t("diaper.__selectRingTone__")}</p>

			{rings.map(ring => (
				<div className="d-flex justify-content-between mb-3" key={ring}>
					<button
						className={ring === selectedRingtone ? "btn btn-sm btn-outline-primary" : "btn btn-sm btn-outline-secondary"}
						type="button"
						onClick={(): void => displayRing(ring)}>
						<FaVolumeUp className="mr-2" />
						{t("diaper.__ringTone__")} {ring}
					</button>
					<FaCheck
						className={ring === selectedRingtone ? "big-blue-check-icon" : "big-grey-check-icon"}
						onClick={(): void => updateUiprefs(ring)}
					/>
				</div>
			))}
		</div>
	);
};
export default IncoRingtone;
