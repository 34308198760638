import React, { useEffect, useState } from "react";
import SeniorDetails from "./SeniorDetails";
import FamilyUser from "./Family/FamilyUser";
import Documents from "./SeniorDocument/Documents";
import HeaderContainer from "../../layout/HeaderContainer";
import { useTranslation } from "react-i18next";
import HandleSuccess from "../../layout/HandleSuccess";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as p from "../../constants/Permissions";
import { StoreInterface, UserInterface } from "../../interfaces/UserInterface";
import { RoomInterface } from "../../interfaces/HomeInterface";

interface Props {
	seniorId: number;
	homeId: number;
	userState: UserInterface;
	store: StoreInterface;
	dispatch: React.Dispatch<any>;
	newSeniorDispatch: React.Dispatch<any>;
	display:boolean;
}

export default function SeniorFullProfile({ seniorId, userState, homeId, store, dispatch, newSeniorDispatch, display}: Props): JSX.Element {
	const { t } = useTranslation("common");
	const [room, setRoom] = useState<string | RoomInterface>("");
	const [show, setShow] = useState({ show: false, info: null, title: t("senior.__seniors__"), success: false });
	const [homeUnit, setHomeUnit] = useState<string | null>("");
	const [seniorSelected, setSeniorSelected] = useState({});
	const [editMode, setEditMode] = useState({ edit: false, senior: null });
	const canManageSeniorDocuments = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.CAN_MANAGE_SENIOR_DOCUMENTS
	]);
	const canEditFamily = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_SENIOR]);

	useEffect(() => {
		if (seniorId) {
			http
				.get(`${url.BASEURL}/seniors/${seniorId}`)
				.then(res => setSeniorSelected(res.data.senior))
				.catch(() => setSeniorSelected({}));
		}
	}, [seniorId, editMode, show.success]);

	useEffect(() => {
		if (seniorId && store.home.rooms.length > 0) {
			const seniorRoom = store.home.rooms.find(room => room.senior_id === seniorId);
			if (seniorRoom) {
				setRoom(seniorRoom);
				if (seniorRoom.home_unit_id) {
					const homeUnit = DisplayName(seniorRoom.home_unit_id, store.home.homeUnits);
					setHomeUnit(homeUnit);
				}
			}
		}
	}, [seniorId, store.home.rooms, store.home.homeUnits]);

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("senior.__seniors__")}
				addButton={t("senior.__addSenior__")}
				link={"/senior/addsenior/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_CREATE_SENIOR]}
				userState={userState}
			/>
			<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} />
			<SeniorDetails
				seniorSelected={seniorSelected}
				userState={userState}
				room={room}
				homeUnit={homeUnit}
				homeId={homeId}
				store={store}
				dispatch={dispatch}
				editMode={editMode}
				setEditMode={setEditMode}
				show={show}
				setShow={setShow}
			/>
			<div className="space"></div>
			<FamilyUser seniorId={seniorId} canEditFamily={canEditFamily}  newSeniorDispatch={newSeniorDispatch} display={display} />
			{canManageSeniorDocuments ? <Documents seniorId={seniorId} userState={userState} /> : null}
		</div>
	);
}
