export const RhtWebSocket = (socket, homeId, diaperDispatch) => {
	const rhtChannel = socket.channel(`rht:${homeId}`);
	console.log("[DEBUG WS] rht channel > ", rhtChannel);

	rhtChannel.onError(() => {
		console.log("rht channel RHT > there was an error!");
	});

	rhtChannel.onClose(() => {
		console.log("rht channel > has gone away gracefully");
	});

	rhtChannel.on("SENIOR_RHT", msg => {
		console.log("rht channel > on", msg);
		if (msg) {
			diaperDispatch({
				type: "EDIT_SENIORSWITHDIAPER",
				payload: msg
			});
		}
	});

	rhtChannel
		.join()
		.receive("ok", ({ messages }) => console.log("rht channel >  catching up", messages))
		.receive("error", ({ reason }) => console.log("rht channel >  failed join", reason))
		.receive("timeout", () => console.log("rht channel > Networking issue. Still waiting..."));
};

export const DiaperWebSocket = (socket, homeId, setRefetch, selectedRingtone) => {
	const diapersChannel = socket.channel(`diapers:${homeId}`);
	console.log("[DEBUG WS] Diapers channel > ", diapersChannel);

	diapersChannel.onError(() => {
		console.log("Diapers channel > there was an error!");
	});

	diapersChannel.onClose(() => {
		console.log("Diapers channel > has gone away gracefully");
	});

	diapersChannel.on("msg", msg => {
		console.log("Diapers channel > on", msg);
		if (msg.data) {
			if (msg.isAlert) {
				const audio = new Audio(`${process.env.PUBLIC_URL}/audio/inco_notification_${selectedRingtone}.mp3`);
				audio.play();
			}
			setRefetch(prevState => !prevState);
		}
	});

	diapersChannel
		.join()
		.receive("ok", ({ messages }) => console.log("Diapers channel >  catching up", messages))
		.receive("error", ({ reason }) => console.log("Diapers channel >  failed join", reason))
		.receive("timeout", () => console.log("Diapers channel > Networking issue. Still waiting..."));
};

export const PanelWebSocket = (socket, homeId, setFetchCalendar, setFetchMessages) => {
	const channel = socket.channel(`controlpanel:${homeId}`);
	console.log("[DEBUG WS] Control panel channel > ", channel);

	channel.onError(() => {
		console.log("Control panel channel > there was an error!");
	});

	channel.onClose(() => {
		console.log("Control panel channel > the channel has gone away gracefully");
	});

	channel.on("controlpanel", msg => {
		console.log("Control panel channel > ", msg);
		if (msg.data === "CALENDAR") {
			setFetchCalendar(prevState => !prevState);
		}
		if (msg.data === "MESSAGE") {
			setFetchMessages(prevState => !prevState);
		}
	});

	channel
		.join()
		.receive("ok", ({ messages }) => console.log("Control panel channel > catching up", messages))
		.receive("error", ({ reason }) => console.log("Control panel channel > failed join", reason))
		.receive("timeout", () => console.log("Control panel channel > Networking issue. Still waiting..."));
};

export const NotificationSocket = (socket, homeId, setBell, setNewNotification) => {
	setBell({ new: false, read: true });

	let channel = socket.channel(`notifications:${homeId}`);
	console.log("[DEBUG WS] channel notifications", channel);

	channel.onError(() => {
		setBell({ new: false, read: true });
		setNewNotification(null);
		console.log("Channel notifications > there was an error!");
	});

	channel.onClose(() => {
		setBell({ new: false, read: true });
		setNewNotification(null);
		console.log("Channel notifications > the channel has gone away gracefully");
	});

	channel.on("notification", msg => {
		setNewNotification(msg);
		setBell({ new: true, read: false });
		const handleNotification = () => {
			setBell({ new: false, read: false });
		};
		setTimeout(handleNotification, 4100);
	});

	channel
		.join()
		.receive("ok", ({ messages }) => console.log("Channel notifications > ok", messages))
		.receive("error", ({ reason }) => console.log("Channel notifications > error", reason))
		.receive("timeout", () => console.log("Channel notifications > Networking issue. Still waiting..."));
};
