import React, { useState, useEffect } from "react";
import "./CustomCalendar.scss";
import moment from "moment";
import http from "../services/Interceptor";
import * as url from "../constants/Url";

// ToolTip
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

// FullCalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import frLocale from "@fullcalendar/core/locales/fr";
import nlLocale from "@fullcalendar/core/locales/nl";
import deLocale from "@fullcalendar/core/locales/de";

function getFullLocale(locale) {
	if (locale === "fr") {
		return frLocale;
	} else if (locale === "nl") {
		return nlLocale;
	} else if (locale === "de") {
		return deLocale;
	} else {
		return null;
	}
}

function CustomCalendar({ homeId, locale, seniorsFromHome }) {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [events, setEvents] = useState([]);

	// const handleDateSelect = (selectInfo) => {
	//   setCalendarStartDate(selectInfo.startStr);
	//   setShow(true);
	// }

	// fetch events from the date range displayed
	const handleDates = rangeInfo => {
		setStartDate(moment(rangeInfo.startStr).format("YYYY-MM-DDTHH:mm:ss"));
		setEndDate(moment(rangeInfo.endStr).format("YYYY-MM-DDTHH:mm:ss"));
	};

	const displaySeniorName = id => {
		const senior = seniorsFromHome.find(senior => senior.id === id);
		return senior ? senior.label : null;
	};

	useEffect(() => {
		if (startDate && homeId && endDate) {
			http
				.get(
					`${url.HOMESURL}/${homeId}/home_calendar_entries/from/${encodeURIComponent(startDate)}/to/${encodeURIComponent(
						endDate
					)}`
				)
				.then(res => {
					let eventsList = res.data.calendar_entries;
					const exEvents = eventsList.map(function (event) {
						return {
							...event,
							type: event.entry_type_id,
							title: event.info,
							start: event.begin_datetime,
							end: event.end_datetime,
							detail: event.detail,
							allDay: false,
							senior: event.entry_type_id === 11 ? displaySeniorName(event.senior_id) : null
						};
					});
					setEvents(exEvents);
				})
				.catch(() => setEvents([]));
		}
		// eslint-disable-next-line
	}, [homeId, startDate, endDate, seniorsFromHome]);

	// const eventClick = e => {
	// 	console.log('id', e.event._def.publicId)
	// }

	// Wraps onMouseOver for tooltip.
	const eventMouseEnter = info => {
		if (info.event.extendedProps.detail) {
			tippy(info.el, {
				content: info.event.title + " : " + info.event.extendedProps.detail,
				placement: "top"
			});
		} else if (info.event.extendedProps.entry_type_id === 11) {
			tippy(info.el, {
				content: info.event.title + " avec " + info.event.extendedProps.senior,
				placement: "top"
			});
		} else {
			tippy(info.el, {
				content: info.event.title,
				placement: "top"
			});
		}
	};

	function changeEventColor(info) {
		let type = parseInt(info);
		switch (type) {
			case 2:
				return "event-type";
			case 11:
				return "visio-type";
			default:
				return "workshop-type";
		}
	}

	const renderEventContent = eventInfo => {
		if (eventInfo.event.extendedProps.entry_type_id === 11) {
			return (
				<div className={changeEventColor(eventInfo.event._def.extendedProps.type)}>
					{eventInfo.timeText} : VISIO
					<br /> {eventInfo.event.extendedProps.senior ? eventInfo.event.extendedProps.senior : null}
				</div>
			);
		} else {
			return (
				<div className={changeEventColor(eventInfo.event._def.extendedProps.type)}>
					{eventInfo.timeText} : {eventInfo.event.title}
				</div>
			);
		}
	};

	return (
		<>
			<FullCalendar
				// configuration
				headerToolbar={{
					left: "dayGridMonth,timeGridWeek,timeGridDay",
					center: "title",
					right: "prev,next"
				}}
				handleWindowResize={true}
				initialView="timeGridWeek"
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
				locale={getFullLocale(locale)}
				// Range of the dates displayed
				datesSet={handleDates}
				// events
				events={events}
				eventContent={renderEventContent}
				// user action handlers
				// dateClick={dateClick}
				// eventClick={eventClick}
				eventMouseEnter={eventMouseEnter}
				// Select date to create an event
				// selectable={true}
				// select={handleDateSelect}

				nowIndicator={true}
				// dayMaxEvents={true}
				height={760}
			/>
		</>
	);
}

export default CustomCalendar;
