import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMapMarkerAlt, FaCheck, FaRegEdit, FaInfoCircle, FaAngleRight } from "react-icons/fa";
import AmdSeniorSync from "./AmdSeniorSync";
import AmdProfileSyncInput from "./AmdProfileSyncInput";
import Loader from "../../../layout/Loader";
import ModalContainer from "../../../utils/ModalContainer";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { UserInterface } from "../../../interfaces/UserInterface";

interface Props {
	homeId: number;
	isAdminOrDiaperManager: boolean;
	isAdmin: boolean;
	userState: UserInterface;
}

const AmdProfileSync = ({ homeId, isAdminOrDiaperManager, isAdmin, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [partnerId, setPartnerId] = useState<{ home_partner_id: number; remote_id: number; remote_url: string } | null>(
		null
	);
	const [isLoading, setIsLoading] = useState(false);
	const [syncIsDone, setSyncIsDone] = useState(false);
	const [show, setShow] = useState({ show: false, info: null });
	const [displayAmdHomeInfo, setDisplayAmdHomeInfo] = useState(false);

	useEffect(() => {
		if (homeId && isAdminOrDiaperManager) {
			setIsLoading(true);
			http
				.get(`${url.HOMESURL}/${homeId}/amd_profile/partner_id`)
				.then(res => {
					setIsLoading(false);
					setPartnerId(res.data);
				})
				.catch(() => setIsLoading(false));
		}
	}, [homeId, isAdminOrDiaperManager, syncIsDone]);

	return (
		<div className="mt-2">
			<h2>{t("common.__sync__")}</h2>
			{isLoading ? (
				<Loader />
			) : partnerId && partnerId.home_partner_id ? (
				<div className="mt-4">
					<div className="d-flex justify-content-between">
						<h3>
							<FaMapMarkerAlt className="mr-2" style={{ width: "auto", height: "1.25rem" }} />
							{t("common.__aHome__")}
						</h3>
						<FaInfoCircle
							className="moderation-card-icon-ok"
							onClick={(): void => setDisplayAmdHomeInfo(prevState => !prevState)}
						/>
					</div>
					{displayAmdHomeInfo ? (
						<div className="header-details">
							<div className="m-3">
								<p>
									<FaAngleRight className="mr-2" />
									<b>{t("diaper.__howToFindAmdProfileInfo__")}</b>
								</p>
								<p>{t("diaper.__amdProfilHomeInfo__")}</p>
								<img src="/img/amd/amd_home_access.png" className="img-fluid mt-3" alt="Page AMD Profil" />
							</div>
						</div>
					) : null}
					<div className="recipient-badge mt-2">
						<div className="d-flex justify-content-between">
							<p className="mt-1">
								<b>ID Etablissement: {partnerId.home_partner_id}</b>
								<br />
								Code site: {partnerId.remote_id}
								<br />
								URL site : {partnerId.remote_url}
							</p>

							<div className="right mt-1">
								<FaCheck className="big-check-icon" />
								{isAdmin ? (
									<FaRegEdit
										className="big-sync-icon ml-3"
										role="button"
										onClick={(): void => setShow({ show: true, info: null })}
									/>
								) : null}
							</div>
						</div>
					</div>
					<br />
					<AmdSeniorSync homeId={homeId} partnerId={partnerId} userState={userState} />
				</div>
			) : isAdmin ? (
				<AmdProfileSyncInput homeId={homeId} partnerId={partnerId} setSyncIsDone={setSyncIsDone} />
			) : (
				<p className="meta">{t("diaper.__homeNotSync__")}</p>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<AmdProfileSyncInput
					show={show}
					setShow={setShow}
					homeId={homeId}
					partnerId={partnerId}
					setSyncIsDone={setSyncIsDone}
				/>
			</ModalContainer>
		</div>
	);
};
export default AmdProfileSync;
