import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UpdateUserAccount from "./UpdateUserAccount";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import { FaTimes } from "react-icons/fa";
import { UserProfileInterface } from "../../interfaces/UserInterface";

interface Props {
	userDispatch: React.Dispatch<any>;
}

const UserData = ({ userDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [userData, setUserData] = useState<UserProfileInterface | null>(null);
	const [editMode, setEditMode] = useState({ edit: false, success: false });
	const [error, setError] = useState(false);

	useEffect(() => {
		http
			.get(`${url.BASEURL}/me`)
			.then(res => setUserData(res.data.user))
			.catch(() => setUserData(null));
	}, []);

	const displayLanguage = (locale: string): string => {
		switch (locale) {
			case "fr":
				return t("users.__french__");
			case "nl":
				return t("users.__dutch__");
			case "de":
				return t("users.__german__");
			default:
				return t("users.__english__");
		}
	};

	return (
		<div className="big-card">
			<div className="row form-inline">
				<div className="col-6">
					<h2>{t("users.__myAccount__")}</h2>
				</div>
				<div className="col-6">
					{editMode.edit ? (
						<button
							type="button"
							className="btn btn-outline-secondary float-right btn-sm"
							onClick={(): void => setEditMode({ edit: false, success: false })}>
							{t("common.__cancel__")}
						</button>
					) : (
						<button
							type="button"
							className="btn btn-outline-primary float-right btn-sm"
							onClick={(): void => setEditMode({ edit: true, success: false })}>
							{t("common.__update__")}
						</button>
					)}
				</div>
			</div>

			{editMode.edit ? (
				<UpdateUserAccount
					user={userData}
					setEditMode={setEditMode}
					setUserData={setUserData}
					userDispatch={userDispatch}
				/>
			) : userData ? (
				<div className="row">
					<div className="col-md-12 mt-3">
						{editMode.success && (
							<p className="success-msg mt-2">
								<span
									className="right"
									role="button"
									tabIndex={0}
									onClick={(): void => setEditMode({ edit: false, success: false })}>
									<FaTimes />
								</span>
								{t("users.__editUserSuccess__")}
							</p>
						)}
						{error && (
							<p className="error-msg">
								{t("common.__editError__")}
								<span className="right" role="button" tabIndex={0} onClick={(): void => setError(false)}>
									<FaTimes />
								</span>
							</p>
						)}
						<h5 className="mb-3">
							{userData.first_name} {userData.last_name}
						</h5>
					</div>
					<div className="col-md-4">
						<p>{t("common.__login__")}:</p>
					</div>
					<div className="col-md-8">
						<b>{userData.login}</b>
					</div>
					<div className="col-md-4">
						<p>{t("common.__mail__")} :</p>
					</div>
					<div className="col-md-8">
						<p>{userData.email}</p>
					</div>
					<div className="col-md-4">
						<p>{t("common.__phone__")} :</p>
					</div>
					<div className="col-md-8">
						<p>{userData.phone}</p>
					</div>
					<div className="col-md-4">
						<p>{t("common.__smartphone__")} :</p>
					</div>
					<div className="col-md-8">
						<p>{userData.mobile_phone}</p>
					</div>
					<div className="col-md-4">
						<p>{t("common.__language__")} :</p>
					</div>
					<div className="col-md-8">
						<p>{displayLanguage(userData.locale)}</p>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default UserData;
