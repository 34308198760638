import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";
import { DeviceInterface } from "../../interfaces/HomeInterface";

interface Props {
	editMode: { edit: boolean; device: DeviceInterface | null };
	setEditMode: (args: { edit: boolean; device: DeviceInterface | null }) => void;
	devicesDispatch: React.Dispatch<any>;
	deviceSNType: { id: string; name: string }[];
}

interface FormProps {
	homeId: number;
	type: string;
	serialNumber: string;
}

const EditDevice = ({ editMode, setEditMode, devicesDispatch, deviceSNType }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [changeHome, setChangeHome] = useState(false);
	const [error, setError] = useState(false);
	const methods = useForm<FormProps>();
	const { handleSubmit } = methods;
	const [allHomes, setAllHomes] = useState([]);
	const device = editMode.device;

	useEffect((): any => {
		let mounted = true;
		if (mounted) {
			http
				.get(`${url.BASEURL}/homes`)
				.then(res => setAllHomes(res.data.homes))
				.catch(() => setAllHomes([]));
		}
		return () => (mounted = false);
	}, []);

	const onSubmit = (data: FormProps): void => {
		setError(false);
		let deviceData = {};
		if (changeHome) {
			deviceData = {
				device: {
					home_id: data.homeId,
					serial_number_type: data.type,
					serial_number: data.serialNumber
				}
			};
		} else {
			deviceData = {
				device: {
					serial_number_type: data.type,
					serial_number: data.serialNumber
				}
			};
		}
		if (device) {
			http
				.put(`${url.BASEURL}/devices/${device.id}`, deviceData)
				.then(res => {
					devicesDispatch({
						type: "EDIT_DEVICE",
						payload: { ...res.data.device, home: device.home, roomName: device.roomName, roomId: device.roomId }
					});
					devicesDispatch({
						type: "DISPLAY_SUCCESS",
						payload: t("device.__editSuccess__")
					});
				})
				.then(() => setEditMode({ edit: false, device: null }))
				.catch(() => setError(true));
		}
	};

	return (
		<div className="small-form">
			<h5>{t("device.__editMode__")} :</h5>

			<HandleError error={error} dispatch={devicesDispatch} message={t("common.__addError__")} />

			<FormProvider {...methods}>
				{device ? (
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-group">
							{changeHome === false ? (
								<>
									<span className="float-right">
										<button className="btn btn-sm btn-outline-primary" type="button" onClick={(): void => setChangeHome(true)}>
											{t("device.__updateHome__")}
										</button>
									</span>
									<p>
										{t("device.__affectation__")} : <strong>{DisplayName(device.home_id, allHomes)}</strong>{" "}
									</p>
								</>
							) : (
								<MyInputSelect
									label={t("common.__home__")}
									name="homeId"
									behavior={{ required: true }}
									list={allHomes}
									value={device.home_id}
								/>
							)}
							<MyInputSelect
								label={t("common.__type__")}
								name="type"
								behavior={{ required: true }}
								list={deviceSNType}
								value={device.serial_number_type}
							/>
							<MyInputText
								label={t("device.__serialNumber__")}
								name="serialNumber"
								behavior={{ required: true }}
								value={device.serial_number}
							/>
						</div>
						<div className="mt-4">
							<button
								className="btn btn-outline-secondary btn-sm mr-2"
								onClick={(): void => setEditMode({ edit: false, device: null })}>
								{t("common.__cancel__")}
							</button>
							<button className="btn btn-primary btn-sm" type="submit">
								{t("common.__update__")}
							</button>
						</div>
					</form>
				) : null}
			</FormProvider>
		</div>
	);
};

export default EditDevice;
