import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import MyInputSelect from "../../../utils/Form/MyInputSelect";
import HandleError from "../../../layout/HandleError";
import HeaderContainer from "../../../layout/HeaderContainer";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { HomeStoreInterface } from "../../../interfaces/HomeInterface";

interface Props {
	homeStore: HomeStoreInterface;
	homeDispatch: React.Dispatch<any>;
	setHomeId: (arg: number) => void;
	tenantId: number | null;
	setTenantId: (arg: number) => void;
	isSuperAdmin: boolean;
	groupId: number | null;
	setGroupId: (arg: number) => void;
}

const AddHome = ({
	homeStore,
	homeDispatch,
	setHomeId,
	groupId,
	tenantId,
	setTenantId,
	isSuperAdmin,
	setGroupId
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit } = methods;
	const groups = homeStore.home.groups;
	const homeTypes = homeStore.home.homeTypes;
	const tenants = homeStore.home.tenants;
	const [groupSelection, setGroupSelection] = useState(false);
	const [groupsFromTenant, setGroupsFromTenant] = useState([]);

	useEffect(() => {
		if (tenantId && groupSelection && isSuperAdmin) {
			http
				.get(`${url.BASEURL}/tenants/${tenantId}/home_groups`)
				.then(res => {
					setGroupsFromTenant(res.data.home_groups);
				})
				.catch(() => setGroupsFromTenant([]));
		}
	}, [tenantId, groupSelection, isSuperAdmin]);

	const onSubmit = (data: any): void => {
		const homeData = {
			home: {
				name: data.name,
				address: data.address,
				type_id: parseInt(data.type),
				group_id: groupSelection ? parseInt(data.group) : null,
				latitude: parseFloat(data.latitude),
				longitude: parseFloat(data.longitude),
				url: data.url,
				phone: data.phone,
				tenant_id: isSuperAdmin ? parseInt(data.tenant) : tenantId
			}
		};
		http
			.post(`${url.BASEURL}/homes`, homeData)
			.then(res => {
				homeDispatch({
					type: "ADD_NEW_HOME",
					payload: res.data.home
				});
				// homeDispatch({
				// 	type: "DISPLAY_SUCCESS",
				// 	payload: t("home.__addHomeSuccess__")
				// });
				setHomeId(res.data.home.id);
				history.push("/manage/me/myHome");
			})
			.catch(() => {
				homeDispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("home.__homes__")}
				addButton={t("home.__addHome__")}
				link={"/support/organisation/addhome/add"}
			/>
			<HandleError error={homeStore.es.error} dispatch={homeDispatch} message={t("common.__addError__")} />
			<FormProvider {...methods}>
				<h5 className="blue">{t("home.__addHome__")} :</h5>

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-card">
						<div className="row">
							{isSuperAdmin ? (
								<div className="col-md-12">
									<MyInputSelect
										label={t("common.__tenant__")}
										name="tenant"
										behavior={{ required: true }}
										list={tenants}
										value={tenantId}
										onChange={(e: { target: { value: string } }): void => setTenantId(parseInt(e.target.value))}
									/>
								</div>
							) : null}
							<div className="col-md-6">
								<MyInputText label={t("common.__name__")} name="name" behavior={{ required: true }} />
							</div>
							<div className="col-md-6">
								<MyInputSelect label={t("common.__type__")} name="type" behavior={{ required: true }} list={homeTypes} />
							</div>
							<div className="col-md-12">
								<MyInputText label={t("home.__address__")} name="address" behavior={{ required: true }} />
							</div>
							<div className="col-md-6">
								<MyInputText
									label={t("common.__phone__")}
									name="phone"
									behavior={{ required: false, maxLength: 15, minLength: 7, pattern: /^[0-9+-]*$/ }}
								/>
							</div>
							<div className="col-md-6">
								<MyInputText label={t("home.__website__")} name="url" behavior={{ required: false }} />
							</div>
							<div className="col-md-6">
								<MyInputText label={t("home.__latitude__")} name="latitude" behavior={{ required: false }} />
							</div>
							<div className="col-md-6">
								<MyInputText label={t("home.__longitude__")} name="longitude" behavior={{ required: false }} />
							</div>
						</div>
					</div>
					<div className="form-card">
						<p>{t("home.__assignedToGroup__")}</p>
						<div className="form-group mb-3">
							<div className="form-check form-check-inline">
								<input
									className="form-check-input"
									type="radio"
									name="group-selection"
									// id="true"
									onChange={(): void => setGroupSelection(true)}
									checked={groupSelection}
								/>
								<label className="form-check-label" htmlFor="true">
									{t("common.__yes__")}
								</label>
							</div>
							<div className="form-check form-check-inline">
								<input
									className="form-check-input"
									type="radio"
									name="group-selection"
									// id="false"
									onChange={(): void => setGroupSelection(false)}
									checked={!groupSelection}
								/>
								<label className="form-check-label" htmlFor="false">
									{t("common.__no__")}
								</label>
							</div>
						</div>
						{groupSelection ? (
							<MyInputSelect
								label={t("common.__groupSelection__")}
								name="group"
								behavior={{ required: false }}
								list={tenantId && groupsFromTenant.length > 0 ? groupsFromTenant : groups}
								value={groupId}
								onChange={(e: { target: { value: string } }): void => setGroupId(parseInt(e.target.value))}
							/>
						) : null}
					</div>
					<button className="btn btn-primary btn-sm" type="submit">
						{t("common.__save__")}
					</button>
				</form>
			</FormProvider>
		</div>
	);
};

export default AddHome;
