import React, { useContext, useEffect, useState } from "react";
import { DiaperContext } from "../context/DiaperContext";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import DiaperCards from "../components/DiaperConnected/Cards/DiaperCards";
import * as p from "../constants/Permissions";
import SelectHomeInput from "../layout/SelectHomeInput";
import { DiaperWebSocket } from "../utils/WebSocket";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	socket: any;
}

const DiaperAlertSocket = ({ userState, homeId, socket }: Props): JSX.Element => {
	const isDiaperManager = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]);
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const [refetch, setRefetch] = useState(false);

	const selectedRingtone =
		userState.userConnected.uiprefs && userState.userConnected.uiprefs.inco_ringtone
			? userState.userConnected.uiprefs.inco_ringtone
			: 1;

	useEffect(() => {
		if (socket && homeId && isDiaperManager) {
			DiaperWebSocket(socket, homeId, setRefetch, selectedRingtone);
		}
	}, [socket, homeId, isDiaperManager]);

	// useEffect(() => {
	// 	if (socket && homeId && isDiaperManager) {
	// 		RhtWebSocket(socket, homeId, diaperDispatch);
	// 	}
	// }, [socket, homeId, isDiaperManager]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.DIAPER_APPLICATION]}>
			{homeId ? (
				<PageWithSecondaryNavLayout
					secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
					component={
						<DiaperCards
							refetch={refetch}
							userState={userState}
							isDiaperManager={isDiaperManager}
							homeId={homeId}
							diaperStore={diaperStore}
							diaperDispatch={diaperDispatch}
							isControlPanel={false}
						/>
					}
				/>
			) : (
				<SelectHomeInput />
			)}
		</AccessControl>
	);
};
export default DiaperAlertSocket;
